import React from 'react';
import { useOrganization, useUser } from '@clerk/clerk-react';
import { Squares2X2Icon } from '@heroicons/react/20/solid';

import { UItils } from '@nearst/ui';
import { useShop } from '@services/ShopProvider';

import styles from '../LocationSelector/LocationSelector.module.scss';

const OrganisationSelector = ({ selectLocation }) => {
	const { organization: activeOrganization } = useOrganization();
	const { user } = useUser();
	const { shop } = useShop();

	return (
		<>
			{user?.organizationMemberships.map((mem) => {
				const isActive = activeOrganization?.id === mem.organization.id;
				const selected = isActive && !shop.id;

				const select = async () => {
					if (!isActive) {
						window.location.href = '/?org=' + mem.organization.id;
					} else {
						selectLocation('org');
					}
				};

				return (
					<div
						key={mem.organization.id}
						title={mem.organization.name}
						className={UItils.classnames(styles.shop, selected && styles.current)}
						onClick={select}
					>
						<div className={styles.shopImage}>
							<Squares2X2Icon />
						</div>
						<div className={styles.shopName}>
							<span>
								<span className={styles.truncate}>{mem.organization.name}</span>
							</span>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default OrganisationSelector;
