import React, { useState } from 'react';
import styles from './styles.module.css';
import { DropdownItem, DropdownMenu } from '@nearst/ui';

/**
 *
 * @param {{initialValue: string, values: string[], children: (measure: string) => React.ReactNode }} param0
 */
const Toggle = ({ initialValue, values, children }) => {
	const [selectedMeasure, setSelectedMeasure] = useState(initialValue);

	return (
		<>
			<div className={styles.toggleContainer}>
				<DropdownMenu primary={false} buttonText={selectedMeasure}>
					{values.map((measure) => (
						<DropdownItem active={selectedMeasure === measure} key={measure} onClick={() => setSelectedMeasure(measure)}>
							{measure}
						</DropdownItem>
					))}
				</DropdownMenu>
			</div>
			{children(selectedMeasure)}
		</>
	);
};

export default Toggle;
