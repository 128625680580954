import React from 'react';
import * as Stats from '../../../../../components/StatsCard';
import Table from '@components/Table';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { format, parse, isAfter } from 'date-fns';
import InsightsWrapper, { getCSVData } from '../../../../../components/Graphs/DataWrapper';
import Download from '../../../../../components/Graphs/Download';
import InsightCardContainer from '../../../components/InsightCardContainer';

import styles from './RecentlyClickedProductsCard.module.scss';
import mixpanel from 'mixpanel-browser';

const RecentlyClickedProductsCard = ({ shop }) => {
	const { startDate, endDate, selectedRange } = useInsightsDate();

	const query = {
		table: 'product_local_ads',
		from: format(startDate, 'yyyy-MM-dd'),
		to: format(endDate, 'yyyy-MM-dd'),
		id: shop.id,
		columns: ['clicks', 'product_name', 'date', 'barcode'],
		filter: [['clicks', '>', 0]],
		sort: [
			{ column: 'date', order: 'desc' },
			{ column: 'clicks', order: 'desc' }
		],
		sum: ['clicks'],
		limit: 1000
	};

	const filename = `recently_clicked_products_${format(startDate, 'yyyy-MM-dd')} ${format(endDate, 'yyyy-MM-dd')}.csv`.replace(/ /g, '_');

	return (
		<InsightCardContainer>
			<Stats.Title>
				<span className={styles.title}>
					Recently clicked products
					<Stats.Tooltip>These are the products that people looked at in the last few days</Stats.Tooltip>
				</span>
			</Stats.Title>
			<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
			<InsightsWrapper query={query}>
				{(data) => {
					if (!data.length) {
						return <p>We don't have enough data to show you this yet.</p>;
					}
					const csv = getCSVData(data);
					return (
						<>
							<Stats.Download csv={csv} filename={filename} />
							<Table
								onExpand={(expanded) =>
									mixpanel.track(expanded ? 'Expand insights table' : 'Collapse insights table', {
										'Table name': 'Recently Clicked Products'
									})
								}
								data={data}
								defaultSort={{
									orderBy: 'date',
									direction: 'desc',
									handler: (a, b) => isAfter(new Date(b.date), new Date(a.date))
								}}
								expandable
								initialLength={6}
								maxLength={40}
								fields={[
									{
										id: 'product',
										title: 'Product name',
										formatter: (row) => {
											return row.product_name;
										},
										width: '65%'
									},
									{
										id: 'date',
										title: 'Date',
										formatter: (row) => {
											return format(parse(row.date, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
										},
										width: '20%',
										sortFn: {
											asc: (a, b) => isAfter(new Date(a.date), new Date(b.date)),
											desc: (a, b) => isAfter(new Date(b.date), new Date(a.date))
										}
									},
									{
										id: 'clicks',
										title: 'Clicks',
										width: '15%',
										formatter: (row) => {
											return row.clicks;
										},
										sortFn: { asc: (a, b) => a.clicks - b.clicks, desc: (a, b) => b.clicks - a.clicks }
									}
								]}
							/>
						</>
					);
				}}
			</InsightsWrapper>
		</InsightCardContainer>
	);
};

export default RecentlyClickedProductsCard;
