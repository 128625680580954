import React, { useState, useEffect } from 'react';

import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import { ActualButton as Button } from '@nearst/ui';
import { naiveIntercom } from '@services/Intercom/utils';
import PageLayout from '../PageLayout';
import styles from './Confirmation.module.scss';

const Confirmation = ({ email }) => {
	const [confette, setConfette] = useState();
	const handleBoot = () => {
		naiveIntercom(email);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setConfette(3);
		}, 2000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<PageLayout>
			<Confetti data-cy="confette" {...useWindowSize()} numberOfPieces={confette} />
			<h1>Verify your email address</h1>
			<p className={styles.confirm}>{`We've sent a login link to ${email}.`}</p>
			<p className={styles.confirm}>
				To complete your NearSt profile and start uploading your inventory, please click the link in the email.
			</p>
			<p className={styles.confirm}>
				{/* is 10 right here? */}
				If you haven't received an email the next 10 minutes, please check your spam or junk mail folder. If you do not receive a
				confirmation email, please reach out to our team.
			</p>
			<p>Questions? Reach out to our team through live chat support:</p>
			<Button onClick={handleBoot}>Contact us</Button>
		</PageLayout>
	);
};

export default Confirmation;
