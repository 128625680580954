import React, { useContext } from 'react';
import { MeasureContext } from '..';
import { DropdownItem, DropdownMenu } from '@nearst/ui';

const Toggle = () => {
	const { selectedMeasure, setMeasure } = useContext(MeasureContext);

	return (
		<DropdownMenu primary={false} buttonText={selectedMeasure === 'impressions' ? 'Impressions' : 'Clicks'}>
			<DropdownItem active={selectedMeasure === 'impressions'} onClick={() => setMeasure('impressions')}>
				Impressions
			</DropdownItem>
			<DropdownItem active={selectedMeasure === 'clicks'} onClick={() => setMeasure('clicks')}>
				Clicks
			</DropdownItem>
		</DropdownMenu>
	);
};

export default Toggle;
