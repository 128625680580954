import React from 'react';

import { TableCellsIcon } from '@heroicons/react/20/solid';

import { useShop } from '@services/ShopProvider';
import Sidebar from '@components/Sidebar';
import { Protect } from '@clerk/clerk-react';

const Reports = () => {
	const { shop, data: shops } = useShop();
	const shopSelected = !!shop?.id;

	const onFreemium = shopSelected ? shop.plan === 'freemium' : shops.every((shop) => shop.plan === 'freemium');
	const swisEnabled = shopSelected ? !!shop.swisUrl : shops.some((shop) => !!shop.swisUrl);

	if (!swisEnabled || onFreemium) return null;

	return (
		<Protect permission="org:reports:read">
			<Sidebar.Item to="/reports">
				<TableCellsIcon />
				Export data
			</Sidebar.Item>
		</Protect>
	);
};

export default Reports;
