import React from 'react';
import { useSession, useAuth } from '@clerk/clerk-react';

import { EyeIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { UItils, Stack, Pill } from '@nearst/ui';
import { useShop } from '@services/ShopProvider';
import { useIdSplice } from '@utils/constructUrlWithId';

import styles from './Alert.module.scss';
import { Link } from 'react-router-dom';

const Alert = ({ children, className = '' }) => {
	return <div className={UItils.classnames(styles.alert, className)}>{children}</div>;
};

const PaymentWarning = () => {
	const { data } = useShop();
	const alertShop = data && data.find((el) => el.paymentIssue);
	const linkPath = useIdSplice('/settings/billing');
	if (alertShop?.currentPlusPlan) {
		return (
			<Alert>
				<strong>Payment failed</strong>
				<p>We tried to charge your account, but something went wrong. Please check your payment method.</p>
				<Link to={linkPath}>Billing settings</Link>
			</Alert>
		);
	}

	return null;
};

const ClerkImpersonator = () => {
	const { session, isLoaded } = useSession();
	const { orgRole } = useAuth();
	if (!isLoaded) return null;
	if (session && session.actor) {
		return (
			<Alert className={styles.clerk}>
				<details className={styles.details}>
					<Stack space="0.6rem">
						<strong>Signed in as {session.publicUserData.identifier}</strong>
						<div>
							<Pill colour="grey">Role: {orgRole}</Pill>
						</div>
					</Stack>
					<summary className={styles.summary}>
						<EyeIcon height={20} />
						Impersonating
						<ChevronUpIcon className={styles.chevron} height={20} />
					</summary>
				</details>
			</Alert>
		);
	}

	return null;
};

Alert.PaymentWarning = PaymentWarning;
Alert.ClerkImpersonator = ClerkImpersonator;

export default Alert;
