import React from 'react';
import { Protect } from '@clerk/clerk-react';
import { InsightsDateProvider } from '@services/InsightsDateProvider';

import useChainAwareRouter from '../../utils/useChainAwareRouter';
import InsightsPages from './router';
import InsightsPagesChain from './router-chain';
import AccessDenied from '../errors/AccessDenied';

const Insights = () => {
	const Router = useChainAwareRouter('/insights/', ['local-ads', 'local-listings', 'industry', 'business-profile']);

	return (
		<InsightsDateProvider>
			<Protect permission="org:insights:read" fallback={<AccessDenied />}>
				<Router shop={(shop) => <InsightsPages shop={shop} />} chain={(chain) => <InsightsPagesChain chain={chain} />} />
			</Protect>
		</InsightsDateProvider>
	);
};

export default Insights;
