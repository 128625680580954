import React from 'react';
import mixpanel from 'mixpanel-browser';
import { format } from 'date-fns';
import * as Stats from '../../../../../components/StatsCard';
import Table from '@components/Table';
import { useInsightsDate } from '@services/InsightsDateProvider';
import InsightsWrapper, { getCSVData } from '../../../../../components/Graphs/DataWrapper';
import Download from '../../../../../components/Graphs/Download';
import InsightCardContainer from '../../../components/InsightCardContainer';

import styles from './LocalSearchTermsCard.module.scss';

const LocalSearchTermsCard = ({ shops }) => {
	const { startDate, endDate, selectedRange } = useInsightsDate();
	const shopIds = shops.map(({ id }) => id);

	const query = {
		table: 'local_search_terms',
		from: format(startDate, 'yyyy-MM-dd'),
		to: format(endDate, 'yyyy-MM-dd'),
		id: shopIds,
		columns: ['clicks', 'impressions', 'search_term'],
		sort: [{ column: 'impressions', order: 'desc' }],
		sum: ['clicks', 'impressions'],
		limit: 50
	};

	const filename = `popular_search_terms_${format(startDate, 'yyyy-MM-dd')} ${format(endDate, 'yyyy-MM-dd')}.csv`.replace(/ /g, '_');

	return (
		<InsightCardContainer>
			<Stats.Title>
				<span className={styles.title}>
					Local search terms
					<Stats.Tooltip>These are the search terms people used most often to find products that your shop sells</Stats.Tooltip>
				</span>
			</Stats.Title>
			<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
			<InsightsWrapper query={query}>
				{(data) => {
					if (!data.length) {
						return <p>We don't have enough data to show you this yet.</p>;
					}
					const csv = getCSVData(data);

					return (
						<>
							<Stats.Download csv={csv} filename={filename} />
							<Table
								initialLength={6}
								maxLength={40}
								onExpand={(expanded) =>
									mixpanel.track(expanded ? 'Expand insights table' : 'Collapse insights table', {
										'Table name': 'Local Ads Search Terms'
									})
								}
								data={data}
								defaultSort={{ orderBy: 'clicks', direction: 'desc', handler: (a, b) => b.clicks - a.clicks }}
								expandable
								fields={[
									{
										id: 'search_term',
										title: 'Search term',
										width: '65%',
										formatter: (row) => {
											return `"${row.search_term}"`;
										}
									},
									{
										id: 'impressions',
										title: 'Impressions',
										width: '20%',
										formatter: (row) => {
											return row.impressions?.toLocaleString();
										},
										sortFn: {
											asc: (a, b) => a.impressions - b.impressions,
											desc: (a, b) => b.impressions - a.impressions
										}
									},
									{
										id: 'clicks',
										title: 'Clicks',
										width: '15%',
										formatter: (row) => {
											return row.clicks?.toLocaleString();
										},
										sortFn: { asc: (a, b) => a.clicks - b.clicks, desc: (a, b) => b.clicks - a.clicks }
									}
								]}
							/>
						</>
					);
				}}
			</InsightsWrapper>
		</InsightCardContainer>
	);
};

export default LocalSearchTermsCard;
