import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Pill } from '@nearst/ui';

const StockPill = ({ stock, row, hideNoStock = false }) => {
	const stockRecord = stock && stock.find(({ barcode }) => barcode === row.barcode);

	if (stock) {
		if (!stockRecord || !stockRecord.quantity) {
			if (hideNoStock) return null;
			return <Pill colour="grey">Out of stock</Pill>;
		}
		return <Pill colour="green">{`${stockRecord.quantity} in stock`}</Pill>;
	}
	return <Skeleton animation="wave" variant="text" width={100} height={25.5} />;
};

export { StockPill };
