import useSWR from 'swr';
import myShopClient from './nearlive';
import { startOfWeek, endOfWeek, eachDayOfInterval, format, isWithinInterval } from 'date-fns';

const sortByDate = (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime();

export async function getAverageResults() {
	const client = await myShopClient();
	return (await client.get('/api/local-ads/average-results')).data;
}

export async function getProductFreeListings({ id, from, to }) {
	const client = await myShopClient();
	const { data } = await client.get(`/api/product-local-listings/${id}?start=${from}&end=${to}`);
	return data;
}

export function useProductFreeListings({ id, from, to }) {
	const ids = Array.isArray(id) ? id.sort() : id;
	const key = `${JSON.stringify({ id: ids, from, to })}-product-free-listings'}`;
	const { data: resultSet, error } = useSWR(key, () => getProductFreeListings({ id, from, to }), {
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
		shouldRetryOnError: false
	});
	const isLoading = !resultSet && !error;
	const isEmpty = resultSet && !resultSet.length;
	return { resultSet, error, isLoading, isEmpty };
}

const queryCache = {};
export async function getMultipleInsights(queries) {
	const client = await myShopClient();
	const responses = await Promise.all(
		queries.map(async (query) => {
			const { id, table } = query;
			if (!id || !table) throw Error('required parameters');
			const cacheKey = JSON.stringify(query);
			if (queryCache[cacheKey]) return queryCache[cacheKey];
			const { data } = await client.post(`/api/insights`, query);
			queryCache[cacheKey] = data;
			return data;
		})
	);
	if (queries.length === 1) return responses[0];
	return responses;
}

export async function getIndustryLocalAdsInsights(queries) {
	const client = await myShopClient();

	const response = await Promise.all(
		queries.map((query) => {
			const { category, from, to } = query;
			if (!category || !from || !to) throw Error('required parameters');
			return client.post(`/api/industry-local-ads-insights`, query);
		})
	);

	return response.map((r) => r.data);
}

export function useInsights(request) {
	const { data, error } = useSWR([request], getMultipleInsights);
	const isLoading = !data && !error;
	const isEmpty = data && !data.length;
	return { resultSet: data, error, isLoading, isEmpty };
}

export function useMultipleInsights(requests) {
	const swrResult = useSWR(requests, getMultipleInsights, { suspense: true });
	const { data: resultSet, error } = swrResult;
	const isLoading = !resultSet && !error;
	const isEmpty = resultSet && resultSet.length && resultSet.every((el) => el.length === 0);
	return { resultSet, error, isLoading, isEmpty };
}

export function useData(fetcher, args) {
	const key = `${fetcher.name}_${JSON.stringify(args)}`;
	const { data, error } = useSWR(key, () => fetcher(args));
	const isLoading = !data && !error;
	const isEmpty = !isLoading && data?.length && data.every?.((el) => el?.length === 0);
	const resultSet = data;
	return { resultSet, error, isLoading, isEmpty };
}

export function getDailySwisInsights(fromDate, toDate, dailySwisAll, weeklySwisAll, shopIds) {
	const interpolatedData = [];

	for (let id of shopIds) {
		const shopDailySwis = dailySwisAll.filter((d) => d.shop_id === id);
		const shopWeeklySwis = weeklySwisAll.filter((d) => d.shop_id === id);

		// For each day in the date range, interpolate the impressions data
		for (const date of eachDayOfInterval({ start: fromDate, end: toDate })) {
			const dailySwisEntry = shopDailySwis.find((d) => d.date === format(date, 'yyyy-MM-dd'));

			// If there is daily data for the current day, add it to the new dataset
			if (dailySwisEntry && dailySwisEntry.impressions) {
				interpolatedData.push({
					date: dailySwisEntry.date,
					impressions: dailySwisEntry.impressions,
					shopId: id
				});
			} else {
				const weekStart = startOfWeek(date, { weekStartsOn: 1 });
				const weekEnd = endOfWeek(date, { weekStartsOn: 1 });
				const weeklyEntry = shopWeeklySwis.find(
					(w) => w.start_date === format(weekStart, 'yyyy-MM-dd') && w.end_date === format(weekEnd, 'yyyy-MM-dd')
				);

				// If there is weekly data for the current week, calculate the daily average and add it to the new dataset
				if (weeklyEntry) {
					const dailyEntriesInWeek = shopDailySwis.filter(
						(d) => isWithinInterval(new Date(d.date), { start: weekStart, end: weekEnd }) && d.impressions
					);
					const dailySumInWeek = dailyEntriesInWeek.reduce((sum, curr) => sum + curr.impressions, 0);
					const dailyWeightedAverage = (weeklyEntry.impressions - dailySumInWeek) / (7 - dailyEntriesInWeek.length);
					const weightedAverage = Math.max(0, Math.round(dailyWeightedAverage)); // ensure the value is never less than zero

					interpolatedData.push({
						date: format(date, 'yyyy-MM-dd'),
						impressions: weightedAverage,
						estimate: true,
						shopId: id
					});
				}
			}
		}
	}

	return interpolatedData.sort(sortByDate);
}
