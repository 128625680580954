import React from 'react';
import { Redirect } from 'react-router-dom';

import useChainAwareRouter from '@utils/useChainAwareRouter';
import { useIsOnboarding } from '@components/OnboardingAccessControl';
import { useCheckPermission } from '@services/auth/hooks';

const HomeRouter = () => {
	const Router = useChainAwareRouter('/home/');
	const hasInsightsPermission = useCheckPermission('org:insights:read');
	const isOnboarding = useIsOnboarding();
	const homepage = hasInsightsPermission ? '/insights' : '/orders';

	return (
		<Router
			shop={(shop) => (isOnboarding ? <Redirect to={`/onboarding/${shop.id}`} /> : <Redirect to={`${homepage}/${shop.id}`} />)}
			chain={() => <Redirect to={homepage} />}
		/>
	);
};

export default HomeRouter;
