/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useRouteMatch, Redirect, useLocation } from 'react-router-dom';
import { useShop } from '@services/ShopProvider';

// secondPath is array of allowed second paths e.g. ['facebook', 'google']
// for if we want to handle a url like '/channels/facebook/'
const useChainAwareRouter = (path, secondPath) => {
	const location = useLocation();

	if (secondPath) {
		// combine path and second path to use in routeMatch method
		const secondLocation = location.pathname.split('/')[2];
		if (secondPath.includes(secondLocation)) {
			path = path + secondLocation + '/';
		}
	}

	const match = useRouteMatch(`${path}:id`);
	const url = match && match.url;
	const { data, shop: activeShop } = useShop();
	const isChain = data.length > 1;

	return useCallback(
		({ chain, shop }) => {
			if (!match) {
				if (isChain) {
					return chain(data);
				}
				if (data.length) {
					return <Redirect to={`${path}${!path.endsWith('/') ? '/' : ''}${data[0].id}`} />;
				}
				return <Redirect to="/404" />;
			}
			if (!activeShop.id) {
				return <Redirect to="/404" />;
			}

			return shop(activeShop);
		},
		[url]
	);
};

export default useChainAwareRouter;
