import React, { useContext } from 'react';
import * as Chart from 'recharts';
import * as utils from '../utils';
import { MeasureContext } from '..';
import styles from './PerformanceGraph.module.scss';
import { useShop } from '@services/ShopProvider';

const Performance = ({ options, org, ...props }) => {
	const { selectedMeasure } = useContext(MeasureContext);
	const data = options[selectedMeasure];
	const { data: shops } = useShop();

	return (
		<div className={styles.chartContainer}>
			<Chart.ResponsiveContainer className={styles.responsiveContainer} width="100%" height={325} {...props}>
				<Chart.BarChart
					aria-roledescription="Bar chart"
					style={{
						fontSize: '0.8rem',
						fontFamily: "'nearst-body', -apple-system, 'Helvetica Neue', 'Arial', sans-serif"
					}}
					margin={{ top: 5, right: 30, bottom: 30, left: 25 }}
					data={data}
				>
					<Chart.XAxis
						aria-roledescription="axis"
						aria-orientation="horizontal"
						aria-label="Shop name"
						dataKey="shopName"
						interval={0}
						tick={(props) => {
							const { x, y, payload } = props;

							return (
								<g className={styles.tick} transform={`translate(${x - payload.offset},${y})`} height={props.height}>
									<foreignObject textAnchor="middle" x={0} y={0} width={payload.offset * 2} height={100}>
										<div className={styles.tickContent} xmlns="http://www.w3.org/1999/xhtml">
											{utils.removeOrgPrefix(payload.value, org)}
										</div>
									</foreignObject>
								</g>
							);
						}}
					/>
					<Chart.YAxis
						yAxisId="lia"
						aria-label={selectedMeasure}
						aria-roledescription="axis"
						aria-orientation="vertical"
						tickFormatter={(value) => value.toLocaleString()}
					>
						<Chart.Label
							style={{ fontWeight: 'bold', textAnchor: 'middle' }}
							angle={-90}
							offset={20}
							value={`Local Ads ${utils.toTitleCase(selectedMeasure)}`}
							position="left"
						/>
					</Chart.YAxis>
					<Chart.YAxis
						orientation="right"
						yAxisId="swis"
						aria-label={selectedMeasure}
						aria-roledescription="axis"
						aria-orientation="vertical"
						tickFormatter={(value) => value.toLocaleString()}
					>
						<Chart.Label
							style={{ fontWeight: 'bold', textAnchor: 'middle' }}
							textAnchor="middle"
							angle={-90}
							offset={20}
							value={`Local Listings ${selectedMeasure}`}
							position="right"
						/>
					</Chart.YAxis>

					<Chart.Tooltip
						formatter={(value, name, props) => {
							const tooltipShop = shops.find((shop) => shop.name.includes(props.payload.shopName));

							if (tooltipShop.totalAdsBudget === 0 && name === 'lia') {
								return ['Off', 'Local Ads'];
							} else {
								return [value.toLocaleString(), `${name === 'swis' ? 'Local Listings' : 'Local Ads'} ${selectedMeasure}`];
							}
						}}
						contentStyle={{
							backgroundColor: 'var(--blue-darkest)',
							border: 'none',
							color: 'white',
							padding: '0.5rem',
							lineHeight: '1.25',
							borderRadius: '5px'
						}}
						cursor={{ fill: 'var(--grey-lightest' }}
					/>

					<Chart.Bar yAxisId="lia" dataKey="lia" fill="#50a1ff" fillOpacity={1} aria-label="lia" />
					<Chart.Bar yAxisId="swis" dataKey="swis" fill="#2aca79" fillOpacity={1} aria-label="swis" />
				</Chart.BarChart>
			</Chart.ResponsiveContainer>
		</div>
	);
};

export default Performance;
