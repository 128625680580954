import { Filter } from '@nearst/ui';
import React, { useState } from 'react';

import plurify from '../../../../utils/plurify';
import styles from './LocationFilter.module.scss';

/**
 * Dropdown to select multiple different locations
 *
 * @param {Object} props
 * @param {object[]} props.shops - An array of shops
 * @param {string[]} props.selectedShops - An array of shop ids that are already selected (react state)
 * @param {React.Dispatch<React.SetStateAction<string[]>>} props.setSelectedShops - State update function to update selected shops
 */
const LocationFilter = ({ shops, selectedShops = shops.map((shop) => shop.id), setSelectedShops }) => {
	const [shopFilterState, setShopFilterState] = useState(
		Object.fromEntries(
			shops.map((shop) => [
				shop.id,
				{
					value: shop.id,
					checked: true,
					label: shop.name
				}
			])
		)
	);
	const [allChecked, setAllChecked] = useState(true);

	const handleShopChange = (event, checked) => {
		const affectedId = event.target.value;
		const affectedShopState = shopFilterState[affectedId];
		const newFilterState = { ...shopFilterState, [affectedId]: { ...affectedShopState, checked } };
		setShopFilterState(newFilterState);

		if (checked === false) {
			setAllChecked(false);
		}

		if (checked === true && Object.values(newFilterState).every((row) => row.checked)) {
			setAllChecked(true);
		}
	};

	const handleApplyClick = async () => {
		const activeShopIds = Object.entries(shopFilterState)
			.filter(([_, value]) => value.checked)
			.map(([key]) => key);
		setSelectedShops(activeShopIds);
	};

	const handleToggleAll = (checked) => {
		setShopFilterState(
			Object.fromEntries(
				shops.map((shop) => [
					shop.id,
					{
						value: shop.id,
						checked,
						label: shop.name
					}
				])
			)
		);
	};

	return (
		<Filter.Dropdown
			text={`${selectedShops.length} ${plurify('location', selectedShops.length)} selected`}
			handleApplyClick={handleApplyClick}
			allChecked={allChecked}
			handleCheckAll={(_, checked) => {
				setAllChecked(checked);
				handleToggleAll(checked);
			}}
			popoverClassName={styles.popover}
			className={styles.filterButton}
		>
			<Filter.Fieldset>
				{Object.values(shopFilterState).map((shop) => (
					<Filter.Field key={shop.value} value={shop.value} checked={shop.checked} onChange={handleShopChange}>
						{shop.label}
					</Filter.Field>
				))}
			</Filter.Fieldset>
		</Filter.Dropdown>
	);
};

export default LocationFilter;
