import React from 'react';
import Table from '@components/Table';
import { Icon, UItils } from '@nearst/ui';
import styles from './InsightsTable.module.scss';

export const Ranking = ({ topProduct, diff, position }) => {
	if (topProduct) {
		return (
			<div className={UItils.classnames(styles.ranking, styles.blueIcon)}>
				<Icon>emoji_events</Icon>
				Top product for {topProduct} weeks
			</div>
		);
	}

	if (position === 'up') {
		return (
			<div className={UItils.classnames(styles.ranking, styles.greenIcon)}>
				<Icon>arrow_drop_up</Icon>
				Up {diff} from last week
			</div>
		);
	}

	if (position === 'down') {
		return (
			<div className={UItils.classnames(styles.ranking, styles.greyIcon)}>
				<Icon>arrow_drop_down</Icon>
				Down {diff * -1} from last week
			</div>
		);
	}

	return null;
};

const InsightsTable = ({ products, limit }) => {
	return (
		<>
			<span className={styles.mobile}>
				<Table
					fields={[
						{
							id: 'product',
							title: 'Product',
							formatter: (row) => {
								return (
									<>
										<p>{row.productname}</p>
										<Ranking {...row} />
									</>
								);
							}
						}
					]}
					data={products}
					maxLength={limit}
				/>
			</span>
			<span className={styles.desktop}>
				<Table
					fields={[
						{
							id: 'product',
							title: 'Product',
							formatter: (row) => {
								return <p>{row.productname}</p>;
							}
						},
						{
							id: 'ranking',
							title: 'Ranking',
							formatter: (row) => {
								return <Ranking {...row} />;
							}
						}
					]}
					data={products}
					maxLength={limit}
				/>
			</span>
		</>
	);
};

export default InsightsTable;
