import countryList from 'react-select-country-list';
import { hoursSchema } from './schema';

export const countries = countryList().getData();

export const countryValidation = (inputCountry) => {
	const found = countries.find((country) => country.value === inputCountry || country.label === inputCountry);
	if (!found) return 'Not found';
	return found.value;
};

export const lookupCountryName = (countryCode) => {
	const found = countries.find((country) => country.value === countryCode);
	if (!found) return 'Not found';
	return found.label;
};

export function parseOpeningHours(opening_hours) {
	const schedule = opening_hours.periods;

	const invalidHours = schedule.some((item) => {
		const [error] = hoursSchema.validate(item);
		if (error) {
			console.error(`Validation Error: ${error}`);
			return true;
		} else {
			return false;
		}
	});

	if (!invalidHours) {
		// format hours array to match existing structure to prevent front-end from breaking.
		const formatHours = opening_hours.periods.map((hour) => {
			const {
				close: { day }
			} = hour;
			const {
				close: { time }
			} = hour;
			const { open } = hour;
			const openingTime = open.time.replace(/\b(\d{1,2})(\d{2})/g, '$1:$2');
			const closingTime = time.replace(/\b(\d{1,2})(\d{2})/g, '$1:$2');
			return {
				day,
				close: closingTime,
				open: openingTime
			};
		});
		return formatHours;
	} else {
		console.error(`Hours were not valid`);
		return undefined;
	}
}

export const getGooglePlaceDetailsFromAddress = (query) => {
	const service = new window.google.maps.places.PlacesService(window.document.getElementById('google-places-autocomplete-helper'));
	const { OK } = window.google.maps.places.PlacesServiceStatus;
	return new Promise((resolve, reject) => {
		service.findPlaceFromQuery(
			{
				query,
				fields: ['place_id']
			},
			(result, status) => {
				if (status !== OK) {
					return reject(status);
				}
				return resolve(result);
			}
		);
	});
};

export const getGooglePlaceDetails = (placeId) => {
	const service = new window.google.maps.places.PlacesService(window.document.getElementById('google-places-autocomplete-helper'));
	const { OK } = window.google.maps.places.PlacesServiceStatus;

	return new Promise((resolve, reject) => {
		service.getDetails(
			{
				placeId,
				fields: [
					'name',
					'address_component',
					'adr_address',
					'formatted_address',
					'type',
					'url',
					'website',
					'formatted_phone_number',
					'geometry',
					'opening_hours',
					'type'
				]
			},
			(result, status) => {
				if (status !== OK) {
					return reject(status);
				}
				return resolve(result);
			}
		);
	});
};

export const isEmpty = (details) => {
	if (!details) return false;
	// ignore fields which aren't inputs
	const invalidKeys = ['partnerstackPartnerKey', 'placeId', 'city', 'types', 'signupSource'];
	const validKeysOnly = Object.keys(details).filter((d) => !invalidKeys.includes(d));

	const isValidKeysEmpty = validKeysOnly.every((key) => {
		return details[key] === undefined || details[key] === '';
	});
	return isValidKeysEmpty;
};

export const setValues = (details, setValue) => {
	Object.entries(details).forEach(([fieldName, fieldValue]) => {
		setValue(fieldName, fieldValue, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
	});
};

// default values for development to save typing!
export const marts = {
	name: "Mart's Beauty Store",
	addressLine1: '20 Ropemaker St',
	addressLine2: 'London',
	postcode: 'EC2Y 9AR',
	city: 'London',
	country: 'GB',
	placeId: 'ChIJ4Rbz-ascdkgRdhUalPZ2UFI',
	types: ['store', 'point_of_interest', 'establishment'],
	contactEmail: 'dev+test@near.st',
	contactName: 'Dev Test'
};
