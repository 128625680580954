import React from 'react';
import { Link } from 'react-router-dom';
import { format, startOfYesterday } from 'date-fns';
import { Page, Loader } from '@nearst/ui';

import EducationalBlock from './EducationalBlock';
import UpgradeBanner from './UpgradeBanner';
import InsightsView from './InsightsView';
import DatePicker from '@components/DatePicker';
import { useShop } from '@services/ShopProvider';
import { useInsights } from '@services/insights';

import styles from './LocalAds.module.scss';

const LocalAds = () => {
	const { shop } = useShop();

	const enabled = shop.totalAdsBudget;

	const shopCreationDate = shop.createdAt.split('T')[0];
	const yesterday = format(startOfYesterday(), 'yyyy-MM-dd');

	const { isLoading, isEmpty: hasNoAllTimeResults } = useInsights([
		{
			id: shop.id,
			table: 'local_ads',
			columns: ['shop_id', 'clicks', 'impressions'],
			sum: ['clicks', 'impressions'],
			from: shopCreationDate,
			to: yesterday
		}
	]);
	return (
		<>
			<Page.Header>
				<Link to={`/insights/${shop.id}`}>
					<Page.BackButton>Insights</Page.BackButton>
				</Link>
				<h1>Local Ads</h1>
				<p>Track and compare the performance of your Local Ads campaign.</p>
				<DatePicker showComparison showInterval />
			</Page.Header>
			<Page.Section>
				{!enabled && <UpgradeBanner shop={shop} />}
				{isLoading ? (
					<Loader className={styles.insightsLoader} />
				) : hasNoAllTimeResults ? (
					<EducationalBlock />
				) : (
					<InsightsView shop={shop} />
				)}
			</Page.Section>
		</>
	);
};

export default LocalAds;
