import React from 'react';
import { Icon } from '@nearst/ui';

import styles from './Stats.module.scss';

const Clicks = ({ children }) => {
	return (
		<p className={`${styles.stats}`}>
			<Icon>mouse</Icon>
			{children} clicks
		</p>
	);
};

const Impressions = ({ children }) => {
	return (
		<p className={`${styles.stats}`}>
			<Icon>visibility</Icon>
			{children} impressions
		</p>
	);
};

export { Clicks, Impressions };
