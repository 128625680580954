import useSWR, { mutate } from 'swr';
import myShopClient from './nearlive';
import { differenceInDays } from 'date-fns';

/**
 * @typedef {import('@nearst/channels-data/model/common/mapper').ChannelDomainObject} Channel
 * 
 * /

/**
 * Create a new channel for a shop
 *
 * @param {string} shopId Shop ID - 
 * @param {Channel["type"]} type The type of channel to create
 * @param {object} body The body to be sent to create the channel
 * @returns {Promise<Channel>}
 */
export async function createChannel(shopId, type, body) {
	const client = await myShopClient();
	const { data } = await client.post(`/api/channels/${shopId}/${type}`, body);

	await mutate(['channel', shopId, type]); // invalidate cache

	return data;
}

export const shopIsOnSwis = (shop) => {
	const hasNoRecentUpload =
		!shop.stockInventorySource?.latestIngest?.timestamp ||
		differenceInDays(new Date(), new Date(shop.stockInventorySource?.latestIngest?.timestamp)) > 3;
	if (hasNoRecentUpload) {
		return false;
	}

	const swisWidgetTimedOut = shop.swisWidgetLastSeenAt && differenceInDays(new Date(), new Date(shop.swisWidgetLastSeenAt)) > 3;
	if (swisWidgetTimedOut) {
		return false;
	}

	return !!shop.swisUrl;
};

function getAvailableChannels(shops) {
	const channels = {};
	for (const shop of shops) {
		for (const [channelId, { internal: channelMeta }] of Object.entries(shop.availableChannels)) {
			if (channelId in channels || !channelMeta) continue;
			channels[channelId] = { id: channelId, ...channelMeta };
		}
	}

	return Object.values(channels);
}

/**
 * Returns a list of all the channels available to the given shops.
 * Usage example: `await getChannels([shop])`
 *
 * Returns an array with channel information from `channels.json`,
 * as well as an array of `enabledShops` for each channel. This allows
 * you to determine whether a channel is enabled for a given shop.
 *
 * @param {object[]} shops - An array of shop objects
 * @returns {Promise<Channel[]>} - An array of channel objects
 */
export async function getChannels(shops) {
	const url = shops.length === 1 ? `/api/channels/${shops[0].id}` : `/api/channels`;

	const client = await myShopClient();
	const { data } = await client.get(url);

	const availableChannels = getAvailableChannels(shops);
	for (const availableChannel of availableChannels) {
		const matchingChannelData = data.filter((channel) => channel.type === availableChannel.id && channel.status === 'enabled');
		const isOrgWideEnabled = matchingChannelData.length === 1 && !matchingChannelData[0].shopId;
		if (isOrgWideEnabled) {
			availableChannel.enabledShops = shops.map(({ id }) => id); // all shops in org
		} else {
			availableChannel.enabledShops = matchingChannelData.map(({ shopId }) => shopId);
		}

		// Supplement legacy channel data - Local Ads
		if (availableChannel.id === 'local-ads') {
			availableChannel.enabledShops = shops.filter(({ totalAdsBudget }) => !!totalAdsBudget).map(({ id }) => id);
		}

		// Supplement legacy channel data - Google Local Listings
		if (availableChannel.id === 'google') {
			availableChannel.enabledShops = shops.filter((shop) => shopIsOnSwis(shop)).map(({ id }) => id);
		}

		// Supplement legacy channel data - Shop page
		if (availableChannel.id === 'shop-page') {
			availableChannel.enabledShops = shops.map(({ id }) => id);
		}
	}

	return availableChannels;
}

/**
 * Update a channel for a shop
 *
 * @param {Channel["type"]} type The type of channel to update
 * @param {string} [shopId] Shop ID - If undefined, fetch channels for whole org
 * @returns {Promise<Channel>}
 */
export async function getChannel(type, shopId) {
	const client = await myShopClient();
	const url = shopId === undefined ? `/api/channels/${type}/organisation` : `/api/channels/${shopId}/${type}`;
	const { data } = await client.get(url);

	return data;
}

/**
 * Retrieves order options for a given shop ID.
 * @param {string} shopId
 * @returns {Promise<{ productReservationEnabled: boolean, localCheckoutEnabled: boolean, anyOptionEnabled: boolean, shopId: string, anyOptionHistoricallyEnabled: boolean }>}
 */
export async function getOrderOptions(shopId) {
	const client = await myShopClient();
	const url = `/api/channels/${shopId}/order-options`;
	const { data } = await client.get(url);
	return data;
}

/**
 * Retrieves order options for the current chain.
 * @returns {Promise<[{ productReservationEnabled: boolean, localCheckoutEnabled: boolean, anyOptionEnabled: boolean, shopId: string, anyOptionHistoricallyEnabled: boolean }]>}
 */
export async function getOrderOptionsForOrganisation() {
	const client = await myShopClient();
	const url = `/api/channels/order-options`;
	const { data } = await client.get(url);
	return data;
}

/**
 * Update a channel for a shop
 *
 * @param {string} shopId Shop ID.
 * @param {Channel["type"]} type The type of channel to update
 * @param {Channel["config"]} config The config to update
 * @returns {Promise<Channel>}
 */
export async function updateChannel(shopId, type, config) {
	const client = await myShopClient();
	const { data } = await client.patch(`/api/channels/${shopId}/${type}/update`, { config });

	await mutate(['channel', shopId, type]); // invalidate cache

	return data;
}

/**
 * Enable a channel for a shop
 *
 * @param {Channel["type"]} type The type of channel to enable
 * @param {string} [shopId] Shop ID. If undefined, enables channels for whole org
 * @returns {Promise<Channel>}
 */
export async function enableChannel(type, shopId) {
	const client = await myShopClient();
	const url = shopId === undefined ? `/api/channels/${type}/enable` : `/api/channels/${shopId}/${type}/enable`;
	const { data } = await client.post(url);

	await mutate(['channel', shopId, type]); // invalidate cache

	return data;
}

/**
 * Disable a channel for a shop
 *
 * @param {Channel["type"]} type The type of channel to disable
 * @param {string} [shopId] Shop ID. If undefined, disables channels for whole org
 * @param {object} [options]
 * @param {boolean} [options.clearConfig]
 * @returns {Promise<Channel>}
 */
export async function disableChannel(type, shopId, options = {}) {
	const { clearConfig = false } = options;
	const client = await myShopClient();
	const url = shopId === undefined ? `/api/channels/${type}/disable` : `/api/channels/${shopId}/${type}/disable`;
	const { data } = await client.post(url, null, {
		params: {
			clearConfig: clearConfig ? 'true' : ''
		}
	});

	await mutate(['channel', shopId, type]); // invalidate cache

	return data;
}

/**
 * @typedef {import('swr').SWRResponse} SWRResponse
 * @typedef {import('swr').SWRConfiguration} SWRConfiguration
 * @typedef {SWRResponse} ChannelResponse
 * @property {Channel} ChannelResponse.data
 */

/**
 * Retrieves channels.
 * @param {Channel["type"]} type - The type of channel to retrieve
 * @param {string} [shopId] - The shop id to fetch the channel for. If undefined, fetch channels for whole org
 * @param {SWRConfiguration} [swrOptions] - Optional extra swr config
 * @returns {ChannelResponse}
 */
export const useChannel = (
	type,
	shopId,
	swrOptions = {
		suspense: true
	}
) => {
	const key = [type, shopId].join('_');
	return useSWR(key, () => getChannel(type, shopId), swrOptions);
};

/**
 *
 * @param {string} [channelId] - The id of the channel to fetch definitions for
 * @returns {Promise<[]>} - The channel object
 */
export async function listChannelDefinitions(channelId) {
	const client = await myShopClient();
	const { data } = await client.get(`/api/channels/definitions`);
	if (data && channelId) {
		return data[channelId];
	}
	return data;
}

/**
 * @typedef {SWRResponse} ChannelDefinitionResponse
 * @property {Channel} ChannelDefinitionResponse.data
 */

/**
 * Retrieves channel definitions.
 * @param {string} [channelId] - The id of the channel to fetch definitions for
 * @param {SWRConfiguration} [swrOptions] - Optional extra swr definitions
 * @returns {ChannelDefinitionResponse}
 */
export const useChannelDefinitions = (
	channelId,
	swrOptions = {
		suspense: true
	}
) => {
	const key = `channels-config`;
	return useSWR(key, () => listChannelDefinitions(channelId), swrOptions);
};
