import React from 'react';
import { format, startOfYesterday } from 'date-fns';

import { Pill } from '@nearst/ui';
import DataWrapper from '@components/Graphs/DataWrapper';
import { parseCurrency } from '@utils/currency';

const LIAPill = ({ shop }) => {
	const shopCreationDate = shop.createdAt.split('T')[0];
	const yesterday = format(startOfYesterday(), 'yyyy-MM-dd');

	const query = {
		id: shop.id,
		table: 'local_ads',
		columns: ['shop_id', 'clicks', 'impressions'],
		sum: ['clicks', 'impressions'],
		from: shopCreationDate,
		to: yesterday
	};

	return (
		<DataWrapper query={[query]} Placeholder={null} hideError hideLoader>
			{([result]) => {
				if (result) {
					return (
						<Pill colour={shop.totalAdsBudget > 0 ? 'blue' : 'grey'}>
							LIA {parseCurrency(shop.totalAdsBudget, { currency: shop.billingCurrency }).value.toString()}
						</Pill>
					);
				}
				return null;
			}}
		</DataWrapper>
	);
};

export default LIAPill;
