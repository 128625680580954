import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import InsightsTable from './InsightsTable';
import { postgresQueries, handlePostgresData } from '../../IndustryInsights/utils';

import styles from './IndustryInsights.module.scss';

import * as Stats from '@components/StatsCard';

import { getIndustryLocalAdsInsights } from '@services/insights';
import SimpleWrapper from '@components/Graphs/DataWrapper/SimpleWrapper';

const PopularProducts = ({ category, id }) => {
	const dbQueries = postgresQueries(category);

	if (!category) {
		return (
			<>
				<p>
					We haven't been able to identify a product category for your store just yet. You can explore other categories from
					across the NearSt network by clicking "View more" below and using the dropdown.
				</p>
				<div className={styles.right}>
					<Stats.ViewMore to={`/insights/${id}/industry`}>View more</Stats.ViewMore>
				</div>
			</>
		);
	}

	return (
		<SimpleWrapper args={dbQueries} fetcher={getIndustryLocalAdsInsights}>
			{(data) => {
				const products = handlePostgresData(data);
				return (
					<>
						<InsightsTable limit={5} products={products} />
						<div className={styles.right}>
							<Stats.ViewMore to={`/insights/${id}/industry`}>View more</Stats.ViewMore>
						</div>
					</>
				);
			}}
		</SimpleWrapper>
	);
};

const PopularProductsNotAvailable = () => {
	return (
		<>
			<p>Because of data availability issues the industry insights are temporarily unavailable.</p>
			<p>The team are working hard to make sure they are back next week.</p>
		</>
	);
};

const Card = ({ shops, teaser = false }) => {
	const shop = shops[0];
	return (
		<Stats.Card className={`${styles.card} ${styles.industry}`}>
			<Stats.Column>
				<Stats.Title>Industry insights</Stats.Title>
				<Stats.Subtitle>Top popular products in your category in the last 7 days.</Stats.Subtitle>
				{teaser ? null : (
					<ReactErrorBoundary FallbackComponent={PopularProductsNotAvailable}>
						<PopularProducts {...{ name: shop.name, category: shop.category, id: shop.id }} />
					</ReactErrorBoundary>
				)}
			</Stats.Column>
		</Stats.Card>
	);
};

export default Card;
