import React from 'react';
import { Link } from 'react-router-dom';
import { Page, Stack } from '@nearst/ui';
import DatePicker from '../../../components/DatePicker';
import FreeLocalListings from './FreeLocalListings';
import PopularProducts from './PopularProducts';
import Explainer from './Explainer';
import SwisScreenshot from './SwisScreenshot';
import GMBPerformanceCard from './GMBstats/GMBstats';

import styles from './SWIS.module.scss';
import InsightTeaserOverlay from '../components/InsightTeaserOverlay';
import { useShop } from '@services/ShopProvider';
import UpgradeRequiredBanner from '../../../components/UpgradeRequiredBanner';

const SWIS = () => {
	const { shop } = useShop();

	return (
		<>
			<Page.Header>
				<Link to={`/insights/${shop.id}`}>
					<Page.BackButton>Insights</Page.BackButton>
				</Link>
				<h1>Local Listings</h1>
				<p>Understand the trends in your Google organic listings performance.</p>
				<DatePicker showComparison showInterval />
			</Page.Header>
			<Page.Section>
				{shop.plan === 'freemium' ? (
					<UpgradeRequiredBanner>
						Upgrade your plan to discover how your products are performing across your free local listings.
					</UpgradeRequiredBanner>
				) : null}
				{shop.plan === 'freemium' ? (
					<InsightTeaserOverlay>
						<div className={`${styles.container} ${styles.leftEmphasis} ${styles.teaser}`}>
							<FreeLocalListings teaser />
							<SwisScreenshot teaser />
						</div>
					</InsightTeaserOverlay>
				) : (
					<Stack>
						<div className={`${styles.container} ${styles.leftEmphasis}`}>
							<FreeLocalListings />
							<SwisScreenshot />
						</div>
						<div className={styles.container}>
							<GMBPerformanceCard />
						</div>
						<div className={`${styles.container} ${styles.third}`}>
							<PopularProducts />
							<Explainer />
						</div>
					</Stack>
				)}
			</Page.Section>
		</>
	);
};

export default SWIS;
