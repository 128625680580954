import React from 'react';
import { useLocation } from 'react-router-dom';
import Registration from './Pages/Registration';
import Confirmation from './Pages/Confirmation';

const RegisterRouter = () => {
	const location = useLocation();
	const params = new URLSearchParams(location.search.replace('?', ''));
	const email = params.get('email');
	if (email) return <Confirmation email={email} />;
	return <Registration />;
};

export default RegisterRouter;
