import React from 'react';
import { Route, Switch } from 'react-router-dom';

import InsightsDashboard from './InsightsDashboard';
import LocalListingsChain from './LocalListingsChain';
import LocalAdsChain from './LocalAdsChain';
import BusinessProfile from './BusinessProfile';

const InsightsPagesChain = ({ chain }) => {
	return (
		<Switch>
			<Route path={`/insights/local-listings`} component={LocalListingsChain} />
			<Route path={`/insights/local-ads`} component={LocalAdsChain} />
			<Route path={`/insights/business-profile`} component={BusinessProfile} />
			<Route component={() => <InsightsDashboard shops={chain} isChain />} />
		</Switch>
	);
};

export default InsightsPagesChain;
