import React from 'react';
import { format } from 'date-fns';
import papa from 'papaparse';
import { Loader } from '@nearst/ui';

import { getMultipleInsights } from '@services/insights';
import NoData from '../Placeholders/NoData';
import SimpleWrapper from './SimpleWrapper';

function toTitleCase(str) {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}

export const getCSVData = (
	resultSet,
	excludedHeaders = ['type', 'shop_id', 'id', 'legacy_id', 'campaign_id', 'campaign_name', 'created_at']
) => {
	try {
		const filterOutHeaders = resultSet.map((row) =>
			Object.fromEntries(
				Object.entries(row)
					.filter(([key]) => !excludedHeaders.includes(key))
					.sort(([, value]) => (typeof value === 'number' ? 1 : -1))
					.map(([key, value]) => {
						let newKey = toTitleCase(key.split('_').join(' '));
						let newValue = value;
						if (key === 'time' || key === 'date' || key === 'sales_date') {
							newValue = format(new Date(value), 'P');
						}
						return [newKey, newValue];
					})
			)
		);

		return papa.unparse(filterOutHeaders);
	} catch (e) {
		console.error(e);
	}
};

const LoaderComponent = () => (
	<div className="myshop-graph__content-wrapper">
		<Loader />
	</div>
);
/**
 * @callback children
 * @param {Object[]} resultSet
 *
 * Retrieve an insight.
 * @param {Object} props
 * @param {Object} props.query
 * @param {string} props.query.id - The uuid of the shop to query insights for.
 * @param {`${number}-${number}-${number}`} props.query.from - The date querying insights from - in the format 'yyyy-mm-dd' .
 * @param {`${number}-${number}-${number}`} props.query.to - The date querying insights up to - in the format 'yyyy-mm-dd' .
 * @param {'local_ads' | 'product_local_listings' | 'stock_changes' | 'local_listings' | 'gmb' | 'product_local_ads' | 'local_search_terms' } props.query.table - The name of the table to be queried.
 * @param {Array[] | Object[]} [props.query.filter] - Optional extra filters.
 * @param {string[]} [props.query.columns] - Optional columns to return - if omitted, all columns are returned. Required if using sum or avg.
 * @param {{ column: string; order: string; }[]} [props.query.sort] - Optional sort.
 * @param {String[]} [props.query.sum] - Optional fields to sum. Must be used with the columns field.
 * @param {String[]} [props.query.avg] - Optional fields to average (mean). Must be used with the columns field.
 * @param {Number} [props.query.limit] - Limit the the number of results returned.
 * @param {children} props.children - children is a function (google "render prop") that takes the data response from the query returns a component that uses that data as a prop
 * @param {boolean} [props.hideError=false] - if we want a data load to fail 'silently'
 * @param {boolean} [props.hideLoader=false]
 * @param {function} [props.fetcher=undefined]
 * @param {Array[]} [props.args=undefined]
 * @param {(() => JSX.Element) | undefined} [props.Placeholder=NoData]
 * @param {() => JSX.Element} [props.ErrorPlaceholder=ErrorComponent]
 * @param {() => JSX.Element} [props.LoaderPlaceholder=LoaderComponent]
 */
const InsightsWrapper = ({
	query,
	fetcher = undefined,
	args = undefined,
	children,
	hideError = false,
	hideLoader = false,
	Placeholder = NoData,
	ErrorPlaceholder = NoData,
	LoaderPlaceholder = LoaderComponent
}) => {
	if (query) {
		const isMultiple = Array.isArray(query);
		return (
			<SimpleWrapper
				fetcher={getMultipleInsights}
				args={isMultiple ? query : [query]}
				hideError={hideError}
				hideLoader={hideLoader}
				Placeholder={Placeholder}
				ErrorPlaceholder={ErrorPlaceholder}
				LoaderPlaceholder={LoaderPlaceholder}
			>
				{children}
			</SimpleWrapper>
		);
	} else if (fetcher) {
		return (
			<SimpleWrapper
				fetcher={fetcher}
				args={args}
				hideError={hideError}
				hideLoader={hideLoader}
				Placeholder={Placeholder}
				ErrorPlaceholder={ErrorPlaceholder}
				LoaderPlaceholder={LoaderPlaceholder}
			>
				{children}
			</SimpleWrapper>
		);
	} else {
		throw Error('Missing query/queries parameter');
	}
};

export default InsightsWrapper;
