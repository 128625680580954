import React from 'react';

import { TagIcon } from '@heroicons/react/20/solid';

import Sidebar from '@components/Sidebar';
import OnboardingAccessControl from '@components/OnboardingAccessControl';
import { Protect } from '@clerk/clerk-react';
import { useShop } from '@services/ShopProvider';

const Inventory = () => {
	const { shop } = useShop();
	const shopSelected = !!shop?.id;
	if (!shopSelected) return null;
	return (
		<OnboardingAccessControl allowedState={'shop.pos.first-upload'}>
			<Protect permission="org:inventory:read">
				<Sidebar.Menu>
					<Sidebar.Item to="/inventory">
						<TagIcon />
						Inventory
					</Sidebar.Item>
					<Sidebar.SubMenu prefix="/inventory">
						<Sidebar.SubItem exact to="/inventory">
							Feed overview
						</Sidebar.SubItem>
						<Sidebar.SubItem to="/inventory/items">Manage items</Sidebar.SubItem>
					</Sidebar.SubMenu>
				</Sidebar.Menu>
			</Protect>
		</OnboardingAccessControl>
	);
};

export default Inventory;
