import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useIdSplice } from '@utils/constructUrlWithId';

import { UItils, Logo } from '@nearst/ui';
import styles from './Sidebar.module.scss';
import Alert from '@components/Alert';

/**
 * @typedef {import('react-router-dom').NavLinkProps} NavLinkProps
 */

/**
 * A menu item (navigation)

 * @param {object} props
 * @param {string} [props.className='']
 * @returns {React.JSX.Element}
 */
const Spacer = ({ className = '' }) => {
	return <span className={UItils.classnames(className, styles.spacer)} />;
};

/**
 * A menu item (navigation)
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The content to be rendered inside the menu item.
 * @param {string} [props.className] - Additional class names to apply to the menu item.
 * @param {string[]} [props.activePaths] - An array of paths that should trigger the active state.
 * @param {string} props.to - The target path for the navigation link.
 * @param {object} [props.additionalProperties] - Additional properties to be passed to the NavLink component.
 * @returns {React.JSX.Element}
 */
const Item = ({ children, className = '', activePaths, to, ...additionalProperties }) => {
	const path = useIdSplice(to);

	const checkIsActive = (match, location) => {
		if (activePaths && activePaths.length > 0) {
			return activePaths.some((activePath) => location.pathname.startsWith(activePath));
		}
		return match;
	};

	// handle external links
	if (typeof to === 'string' && to.startsWith('http')) {
		return (
			<li className={UItils.classnames(className, styles.item)}>
				<a target="_blank" rel="noopener noreferrer" href={to} {...additionalProperties}>
					{children}
				</a>
			</li>
		);
	}

	return (
		<li className={UItils.classnames(className, styles.item)}>
			<NavLink to={path} isActive={checkIsActive} activeClassName={styles.activeItem} {...additionalProperties}>
				{children}
			</NavLink>
		</li>
	);
};

/**
 * A submenu item (navigation)
 *
 * @param {NavLinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>} props
 * @returns {React.JSX.Element}
 */
const SubItem = ({ children, className = '', to, ...props }) => {
	return (
		<Item className={UItils.classnames(className, styles.subItem)} to={to} {...props}>
			{children}
		</Item>
	);
};

/**
 * Group a top-level item and a submenu together
 *
 * @param {React.LiHTMLAttributes<HTMLElement>} props
 * @returns {React.JSX.Element}
 */
const Group = ({ children, className = '', ...rest }) => {
	return (
		<div className={UItils.classnames(className, styles.group)} {...rest}>
			{children}
		</div>
	);
};

/**
 * Group a top-level item and a submenu together
 *
 * @param {React.LiHTMLAttributes<HTMLElement>} props
 * @returns {React.JSX.Element}
 */
const Menu = ({ children, className = '', ...rest }) => {
	return (
		<li className={UItils.classnames(className, styles.menu)} {...rest}>
			<ul>{children}</ul>
		</li>
	);
};
/**
 * A submenu - to display nested routes within the sidebar
 *
 * @typedef {object} SubmenuProps
 * @property {string} SubmenuProps.prefix The submenu prefix to mark as expanded - e.g. /insights
 *
 * @param {React.LiHTMLAttributes<HTMLElement> & SubmenuProps} props
 * @returns {React.JSX.Element}
 */
const SubMenu = ({ children, prefix }) => {
	const { pathname } = useLocation();
	const active = pathname.startsWith(prefix);

	if (!active) return null;

	return (
		<li className={styles.submenu}>
			<ul>{children}</ul>
		</li>
	);
};

/**
 * Section for Alerts to live in
 *
 * @param {React.LiHTMLAttributes<HTMLElement>} props
 * @returns {React.JSX.Element}
 */
const Alerts = ({ children, className = '', ...rest }) => {
	return (
		<div className={UItils.classnames(className, styles.alerts)} {...rest}>
			{children}
		</div>
	);
};

/**
 * Main navigation sidebar in MyShop.
 *
 * @param {object} props
 * @param {Boolean} props.mobileExpanded
 * @param {React.ReactNode} props.children
 * @returns {React.JSX.Element}
 */
const Sidebar = ({ mobileExpanded, children }) => {
	return (
		<nav className={UItils.classnames(styles.sidebar, mobileExpanded && styles.mobileExpanded)}>
			<ul>{children}</ul>
			<Alerts>
				<Alert.PaymentWarning />
				<Alert.ClerkImpersonator />
			</Alerts>
			<Logo height="24px" className={styles.logo} />
		</nav>
	);
};

/**
 * Wraps the sidebar and main content, to allow styling the outer layout.
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @returns {React.JSX.Element}
 */
export const SidebarLayout = ({ children }) => <div className={styles.sidebarLayout}>{children}</div>;

Sidebar.Item = Item;
Sidebar.SubItem = SubItem;
Sidebar.Group = Group;
Sidebar.Menu = Menu;
Sidebar.SubMenu = SubMenu;
Sidebar.Spacer = Spacer;
Sidebar.Alerts = Alerts;
export default Sidebar;
