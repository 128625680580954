import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import qs from 'qs';
import { useClerk } from '@clerk/clerk-react';
import { useHistory } from 'react-router-dom';
import { sendAnalytics } from '../../utils/sendAnalytics';
import { shutdownIntercom } from '@services/Intercom/utils';
import LoadingContainer from '@components/LoadingContainer';

export default function Logout() {
	const { signOut } = useClerk();
	const history = useHistory();
	const location = history.location;
	const { shop } = qs.parse(location.search.substring(1));

	useEffect(() => {
		if (signOut) {
			sendAnalytics('Logout', 'Signed out of MyShop', `${shop}`);
			mixpanel.track('Logout');
			mixpanel.reset();
			shutdownIntercom();
			signOut();
			localStorage.clear();
			history.replace('/login');
		}
	}, [signOut, shop, history]);

	return <LoadingContainer />;
}
