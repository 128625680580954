import React from 'react';
import ReactDOM from 'react-dom';
import mixpanel from 'mixpanel-browser';
import { BrowserRouter } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';

import App from './App';
import './index.scss';

// Init MixPanel
mixpanel.init(import.meta.env.PROD ? '4a6a2aabbf45375753d33ebace8685ff' : '74571c2ac65c51a1c943355a94398ba1');

// Init Sentry
if (import.meta.env.PROD) {
	Sentry.init({
		dsn: 'https://aa5d752b34bc4d928ec1d1753fae076f@o62538.ingest.sentry.io/1465310',
		environment: import.meta.env.MODE,

		// This sets the sample rate to be 0%
		replaysSessionSampleRate: 0,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		integrations: [new Sentry.Replay()]
	});
}

// Opt the user out of data tracking if it's not production
if (import.meta.env.DEV) {
	mixpanel.opt_out_tracking();
}
if (import.meta.env.REACT_APP_LOCAL_GA || import.meta.env.PROD) {
	// enable tracking
	ReactGA.initialize('G-CH8YJG3SM6', {
		debug: false, // change to true for chatty console help
		redactEmail: false
	});
	ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
}

// Render ReactDOM
ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<StylesProvider injectFirst>
				<App />
			</StylesProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);
