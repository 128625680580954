import { useEffect, useState } from 'react';
import myShopClient from './nearlive';
import useSWR from 'swr';
import { useHistory } from 'react-router-dom';

export async function batchGetStock(barcodes, shopId) {
	const client = await myShopClient();
	const response = (await client.post(`/api/inventory/${shopId}/batch`, { barcodes })).data;
	return response;
}

/**
 * this returns product data for a barcode
 * @param {String} barcode - The barcode being used to fetch data
 * @param {String} shopId - The shop id
 * @returns {Promise<{barcode:String, title:String, description:String, brand:String, images:String[]}|null>}
 */
export async function getStock(barcode, shopId) {
	const client = await myShopClient();
	const response = (await client.get(`/api/inventory/${shopId}/${barcode}`)).data;
	return response;
}

export async function getInventoryStatus([shopId]) {
	const client = await myShopClient();
	const response = (await client.get(`/api/inventory/${shopId}/inventory-status`)).data;
	return response;
}

export async function exportInventoryIssues(shopId, issueType) {
	const client = await myShopClient();
	const response = (await client.post(`/api/inventory/${shopId}/inventory-issues/export/${issueType}`)).data;
	return response;
}

export async function customiseProduct(barcode, shopId, modification) {
	const client = await myShopClient();
	const response = (await client.put(`/api/inventory/${shopId}/${barcode}`, modification)).data;
	return response;
}

/**
 * Hide or unhide a list of barcodes.
 *
 * Toggles the `enabled` field on the stock row, and returns an array of [{ barcode, success }] objects.
 *
 * @param {String} shopId
 * @param {String[]} barcodes
 * @param {Boolean} enabled
 * @returns {Promise<{barcode:String, success:Boolean}[]>}
 */
export async function hideProducts(shopId, barcodes, enabled) {
	const client = await myShopClient();
	const result = await client.put(`/api/inventory/hide/${shopId}`, { barcodes, enabled });

	return result.data;
}

export function useStock(barcode, shopId, config) {
	const key = `stock_data-${shopId}-${barcode}`;
	const { data, error } = useSWR(key, () => getStock(barcode, shopId), config);
	const isLoading = !data && !error;
	const isEmpty = data && !data.length;
	const resultSet = data && data.length ? data[0] : data;
	return { data: resultSet, error, isLoading, isEmpty };
}

const parseSelectedFilters = (filters) => {
	const selectedFilters = Object.keys(filters)
		.map((filter) => {
			if (filters[filter]) {
				return filter;
			}
			return undefined;
		})
		.filter(Boolean);

	return selectedFilters;
};

async function listInventory(shop, page, query, filters) {
	const queryParam = query ? `&query=${query}` : '';

	const selectedFilters = parseSelectedFilters(filters);
	const filterParams = selectedFilters.length ? `&filterBy=${selectedFilters.join(',')}` : '';

	const client = await myShopClient();
	const response = await client.get(`/api/${shop}/search?page=${page}${queryParam}${filterParams}`);
	return response.data;
}

export const availabilityFilters = {
	in_stock: {
		value: 'in_stock',
		checked: true,
		label: 'In stock'
	},
	out_of_stock: {
		value: 'out_of_stock',
		checked: false,
		label: 'Out of stock'
	}
};

export const statusFilters = {
	edited: {
		value: 'edited',
		checked: false,
		label: 'Edited product'
	},
	hidden: {
		value: 'hidden',
		checked: false,
		label: 'Hidden product'
	}
};

const filters = { ...availabilityFilters, ...statusFilters };
export const defaultFilters = Object.keys({ ...availabilityFilters, ...statusFilters }).reduce((acc, filterKey) => {
	return {
		...acc,
		[filters[filterKey].value]: filters[filterKey].checked
	};
}, {});

const jsonFiltersToComma = (filters) =>
	Object.entries(filters)
		.filter(([, value]) => Boolean(value))
		.map(([key]) => key)
		.join(',');

const commaFiltersToJson = (filters) => Object.fromEntries(filters.split(',').map((key) => [key, true]));

/**
 * @typedef {{ barcode: string, title: string, price: number, quantity_i: number, in_stock: boolean, issues: string[]}} inventory
 */

/**
 *
 * @param {string} shop
 * @returns {{
 * 	inventory: inventory[],
 * 	error: string,
 * 	isLoading: boolean,
 * 	isValidating: boolean,
 * 	isEmpty: boolean,
 * 	totalPages: number,
 * 	changePage: (number) => void,
 * 	page: number,
 * 	searchInput: string,
 * 	setSearchInput: (string) => void,
 * 	filters: {},
 * 	setFilters: ({}) => void,
 * 	applyFilters: () => void,
 *  optimisticUpdate: (cacheUpdater: (object[]), handler: () => Promise) => void
 *  mutate: () => void
 * }}
 */
export function useInventory(shopId) {
	const history = useHistory();
	const qsParams = new URLSearchParams(history.location.search);
	const INITIAL_PAGE = 1;
	const [totalPages, setTotalPages] = useState(INITIAL_PAGE);

	const handleParamChange = (key, value) => {
		const existingParams = new URLSearchParams(history.location.search);
		existingParams.set(key, value);
		history.replace({
			search: `?${existingParams.toString()}`
		});
	};

	const searchInput = qsParams.get('searchInput') ? qsParams.get('searchInput') : '';
	const setSearchInput = (input) => {
		handleParamChange('searchInput', input);
		setPage(INITIAL_PAGE);
	};

	const filters = qsParams.get('filters') ? commaFiltersToJson(qsParams.get('filters')) : defaultFilters;
	const setFilters = (filters) => {
		// only need to keep the truthy filters for functionality - otherwise a long URL!
		const simpleFilters = jsonFiltersToComma(filters);
		handleParamChange('filters', simpleFilters);
		setPage(INITIAL_PAGE);
	};

	const applyFilters = (newFilters) => {
		setFilters(newFilters);
		mutate();
	};

	const page = qsParams.get('page') ? parseInt(qsParams.get('page')) : INITIAL_PAGE;
	const setPage = (page) => {
		handleParamChange('page', page.toString());
	};

	const key = [shopId, page, searchInput, JSON.stringify(filters)].join('_');
	const { data, error, isLoading, isValidating, mutate } = useSWR(key, () => listInventory(shopId, page, searchInput, filters), {
		// no need to re-check this more than once per minute - we don't update stock that often and I've noticed unnecessary refreshes
		dedupingInterval: 1000 * 60 * 1,
		focusThrottleInterval: 1000 * 60 * 1,
		revalidateIfStale: false,
		revalidateOnFocus: false
	});

	const changePage = (page) => {
		if (page < 0 || page > totalPages) {
			throw Error('page out of bounds error');
		}
		setPage(page);
	};

	const isEmpty = data && !data?.inventory?.length;

	useEffect(() => {
		setTotalPages(data?.numOfPages || 0);
	}, [data?.numOfPages]);

	const optimisticUpdate = (cacheUpdater, handler) => {
		const optimisticData = {
			...data,
			inventory: cacheUpdater(data?.inventory)
		};
		mutate(
			async () => {
				await handler();
				return optimisticData;
			},
			{ optimisticData }
		);
	};

	return {
		inventory: data?.inventory,
		error,
		isLoading: isLoading,
		isValidating,
		isEmpty,
		totalPages,
		changePage,
		page,
		searchInput,
		setSearchInput,
		filters,
		setFilters,
		applyFilters,
		optimisticUpdate,
		mutate
	};
}
