import React, { useState, useCallback, useMemo, createContext } from 'react';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import ChainInsights from './ChainInsights';
import * as Stats from '../../../../../components/StatsCard';
import { useInsightsDate } from '@services/InsightsDateProvider';
import Toggle from './Toggle';
import styles from './ChainPerformance.module.scss';
import Overview from '../../Overview';

export const MeasureContext = createContext(null);

const ChainPerformance = ({ chain }) => {
	const { selectedRange } = useInsightsDate();
	const [selectedMeasure, setSelectedMeasure] = useState('impressions');

	const setMeasure = useCallback((measure) => {
		setSelectedMeasure(measure);
	}, []);

	const contextValue = useMemo(
		() => ({
			selectedMeasure,
			setMeasure
		}),
		[selectedMeasure, setMeasure]
	);

	return (
		<Stats.Card className={styles.performance}>
			<MeasureContext.Provider value={contextValue}>
				<Stats.Header>
					<div className={styles.title}>
						<Stats.Title>Local visibility</Stats.Title>
						<Stats.Subtitle>{selectedRange} | Top locations</Stats.Subtitle>
					</div>
				</Stats.Header>
				<Overview shops={chain} />
				<ErrorBoundary>
					<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
						<div></div>
						<Toggle />
					</div>
					<ChainInsights chain={chain} />
				</ErrorBoundary>
			</MeasureContext.Provider>
		</Stats.Card>
	);
};

export default ChainPerformance;
