import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSignIn, useClerk } from '@clerk/clerk-react';
import LoadingContainer from '@components/LoadingContainer';

const AcceptRouter = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const signInToken = queryParams.get('ticket');
	const { signIn, setActive } = useSignIn();
	const { signOut } = useClerk();
	const history = useHistory();

	if (!signInToken) throw Error('No token provided!');

	useEffect(() => {
		const signInWithTicket = async () => {
			if (signInToken) {
				await signOut();

				const res = await signIn.create({
					strategy: 'ticket',
					ticket: signInToken
				});

				setActive({ session: res.createdSessionId }).then(() => {
					history.push('/');
				});
			}
		};

		if (signIn) {
			signInWithTicket();
		}
	}, [signIn, signOut, setActive, history, signInToken]);

	return <LoadingContainer />;
};

export default AcceptRouter;
