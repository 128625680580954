import { lazy } from 'react';
import HomeRouter from './home';
import LoginPage from './login';
import RegistrationRouter from './register';
import InsightsRouter from './insights';
import ImpersonateRouter from './impersonate';
import LogoutPage from './logout';

const InventoryRouter = lazy(() => import('./inventory'));
const SubscriptionRouter = lazy(() => import('./subscription'));
const SettingsRouter = lazy(() => import('./settings'));
const ChannelsRouter = lazy(() => import('./channels'));
const OrdersRouter = lazy(() => import('./orders'));
const OnboardingRouter = lazy(() => import('./onboarding'));
const UpgradeRouter = lazy(() => import('./upgrade'));
const LocationsRouter = lazy(() => import('./locations'));
const ReportsRouter = lazy(() => import('./reports'));

export {
	InsightsRouter,
	InventoryRouter,
	SubscriptionRouter,
	SettingsRouter,
	LoginPage,
	LogoutPage,
	HomeRouter,
	ChannelsRouter,
	OrdersRouter,
	RegistrationRouter,
	OnboardingRouter,
	UpgradeRouter,
	LocationsRouter,
	ImpersonateRouter,
	ReportsRouter
};
