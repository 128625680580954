import React from 'react';
import { format } from 'date-fns';
import { formatGraphOptions } from '../utils';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { getDailySwisInsights } from '@services/insights';
import InsightsWrapper from '../../../../../../components/Graphs/DataWrapper';
import Performance from '../PerformanceGraph';

const ChainInsights = ({ chain }) => {
	const { startDate, endDate, fromStartOfWeekStr, toEndOfWeekStr } = useInsightsDate();

	const shopIds = chain.map((shop) => shop.id);
	const org = chain[0].organisation;

	// limit the number of columns of graph:
	const limit = 10;

	const queries = [
		{
			id: shopIds,
			table: 'local_listings',
			columns: ['impressions', 'clicks', 'shop_id', 'date'],
			from: format(startDate, 'yyyy-MM-dd'),
			to: format(endDate, 'yyyy-MM-dd')
		},
		{
			id: shopIds,
			table: 'local_listings_weekly',
			columns: ['start_date', 'end_date', 'impressions', 'shop_id'],
			filter: [
				['start_date', '>=', fromStartOfWeekStr],
				['end_date', '<=', toEndOfWeekStr]
			],
			sort: [{ column: 'start_date', order: 'desc' }]
		},
		{
			id: shopIds,
			table: 'local_ads',
			columns: ['impressions', 'clicks', 'shop_id'],
			from: format(startDate, 'yyyy-MM-dd'),
			to: format(endDate, 'yyyy-MM-dd'),
			sum: ['impressions', 'clicks']
		}
	];
	return (
		<InsightsWrapper query={queries}>
			{([swisDailyChains, swisWeeklyChains, localAdsData]) => {
				const swisDailyCorrectedChains = [];

				shopIds.forEach((shopId) => {
					const shopName = chain.find((shop) => shop.id === shopId).name;
					const swisDaily = swisDailyChains.filter((d) => d.shop_id === shopId);

					const swisWeekly = swisWeeklyChains.filter((d) => d.shop_id === shopId);
					const swisDailyCorrected = getDailySwisInsights(startDate, endDate, swisDaily, swisWeekly, [shopId]);

					const impressions = swisDailyCorrected.reduce((acc, curr) => {
						acc += curr.impressions;
						return acc;
					}, 0);
					const clicks = swisDailyChains
						.filter((dailySwis) => dailySwis.shop_id === shopId)
						.reduce((acc, curr) => {
							acc += curr.clicks;
							return acc;
						}, 0);
					swisDailyCorrected.forEach((v) => {
						v.shopName = shopName;
					});
					swisDailyCorrectedChains.push({
						impressions,
						clicks: clicks,
						shopName: shopName,
						shopId: shopId,
						tableName: 'local_listings'
					});
				});

				localAdsData.forEach((insight) => {
					insight.tableName = 'local_ads';
					insight.shopName = chain.find((shop) => shop.id === insight['shop_id']).name;
					insight.shopId = insight['shop_id'];
				});

				return <Performance options={formatGraphOptions([swisDailyCorrectedChains, localAdsData], limit)} org={org} />;
			}}
		</InsightsWrapper>
	);
};

export default ChainInsights;
