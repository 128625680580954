import { SignOutButton, SignedIn, SignedOut } from '@clerk/clerk-react';
import React from 'react';
import { Redirect } from 'react-router-dom';

import useClerkLoginState from '../../utils/useClerkLoginState';
import LoadingContainer from '../LoadingContainer';

export default function PrivateRouteComponent({ children }) {
	const state = useClerkLoginState();

	return (
		<>
			<SignedOut>
				<Redirect to="/login" />
			</SignedOut>
			<SignedIn>
				{(!state || state === 'loading') && <LoadingContainer />}
				{state === 'logged-in' && children}
				{state === 'no-organisation' && (
					<>
						<p>You're not connected to any retailer accounts.</p>
						<SignOutButton />
					</>
				)}
			</SignedIn>
		</>
	);
}
