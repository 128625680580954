import React from 'react';
import { Block, Button, Icon } from '@nearst/ui';
import { Link } from 'react-router-dom';
import RestrictedPage from '@components/RestrictedPage/RestrictedPage';

export default function NotFound() {
	return (
		<RestrictedPage>
			<Icon>find_in_page</Icon>
			<h1>Page not found</h1>
			<p>The page you tried to access doesn't exist, or has been moved.</p>
			<Button as={Link} to="/" primary>
				Back home
			</Button>
		</RestrictedPage>
	);
}
