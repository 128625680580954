import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import BusinessProfile from './BusinessProfile';
import IndustryInsights from './IndustryInsights';
import LocalAds from './LocalAds';
import SWIS from './SWIS';
import ClicksToSales from './ClicksToSales';
import InsightsDashboard from './InsightsDashboard';

const ShopChannelsRouter = ({ shop }) => {
	const liaAvailable = shop.availableChannels?.['local-ads'];

	return (
		<Switch>
			<Route path={`/insights/business-profile/`} component={() => <Redirect to={`/insights/${shop.id}/business-profile`} />} />
			<Route path={`/insights/${shop.id}/business-profile`} component={() => <BusinessProfile />} />

			<Route path={`/insights/industry/`} component={() => <Redirect to={`/insights/${shop.id}/industry`} />} />
			<Route path={`/insights/${shop.id}/industry`} component={() => <IndustryInsights shop={shop} />} />

			<Route path={`/insights/local-ads/`} component={() => <Redirect to={`/insights/${shop.id}/local-ads`} />} />
			<Route
				path={`/insights/${shop.id}/local-ads`}
				component={liaAvailable ? LocalAds : () => <Redirect to={`/insights/${shop.id}`} />}
			/>

			<Route path={`/insights/google-swis/`} component={() => <Redirect to={`/insights/${shop.id}/local-listings`} />} />
			<Route path={`/insights/${shop.id}/google-swis`} component={() => <Redirect to={`/insights/${shop.id}/local-listings`} />} />
			<Route path={`/insights/local-listings/`} component={() => <Redirect to={`/insights/${shop.id}/local-listings`} />} />
			<Route path={`/insights/${shop.id}/local-listings`} component={SWIS} />

			<Route path={`/insights/clicks-to-sales/`} component={() => <Redirect to={`/insights/${shop.id}/clicks-to-sales`} />} />
			<Route
				path={`/insights/${shop.id}/clicks-to-sales`}
				component={liaAvailable ? ClicksToSales : () => <Redirect to={`/insights/${shop.id}`} />}
			/>

			<Route path="/insights" component={() => <InsightsDashboard shops={[shop]} shop={shop} />} />
		</Switch>
	);
};

export default ShopChannelsRouter;
