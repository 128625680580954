import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Page, Select } from '@nearst/ui';

import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import Table from './Table';
import { Card } from '../../../components/StatsCard';
import { productCategories, postgresQueries, handlePostgresData } from './utils';
import { sendAnalytics } from '../../../utils/sendAnalytics';
import SimpleWrapper from '../../../components/Graphs/DataWrapper/SimpleWrapper';
import { getIndustryLocalAdsInsights } from '@services/insights';

import styles from './IndustryInsights.module.scss';

const PopularProducts = ({ name, category, id }) => {
	const [selectedCategory, setSelectedCategory] = useState(category);

	const dbQueries = postgresQueries(selectedCategory);

	const handleChange = (e) => {
		const value = e.target.value;
		sendAnalytics(`Popular Products Category: ${value}`, 'click', name);
		setSelectedCategory(value);
	};

	// if coming from the dashboard, may have the products already loaded in state
	let products;
	const location = useLocation();
	if (location) products = location.state;

	return (
		<>
			<Select id="category-selector" value={selectedCategory} onChange={handleChange} className={styles.dropdown}>
				<option disabled selected>
					-- select a category --
				</option>
				{productCategories.sort().map((category) => (
					<option key={category} value={category}>
						{category}
					</option>
				))}
			</Select>

			<div>
				{!selectedCategory ? (
					<p>
						We haven't been able to identify a product category for your store just yet. You can explore the categories from
						across the NearSt network by using the dropdown below.
					</p>
				) : null}
			</div>
			{selectedCategory &&
				(products ? (
					<Table products={products} category={category} selectedCategory={selectedCategory} id={id} />
				) : (
					<SimpleWrapper args={dbQueries} fetcher={getIndustryLocalAdsInsights}>
						{(data) => {
							const products = handlePostgresData(data);
							return <Table products={products} category={category} selectedCategory={selectedCategory} id={id} />;
						}}
					</SimpleWrapper>
				))}
		</>
	);
};

const PopularProductsNotAvailable = () => {
	return (
		<Card>
			<h1>Industry Insights Unavailable</h1>
			<p>Because of data availability issues the industry insights are temporarily unavailable.</p>
			<p>The team are working hard to make sure they are back next week.</p>
		</Card>
	);
};

const IndustryInsights = ({ shop }) => {
	return (
		<>
			<Page.Header>
				<Link to={`/insights/${shop.id}`}>
					<Page.BackButton>Insights</Page.BackButton>
				</Link>
				<h1>Industry insights</h1>
				<p>These are the most popular products across categories in the industry in the last 7 days</p>
			</Page.Header>
			<Page.Section>
				<ReactErrorBoundary FallbackComponent={PopularProductsNotAvailable}>
					<PopularProducts name={shop.name} category={shop.category} id={shop.id} />
				</ReactErrorBoundary>
			</Page.Section>
		</>
	);
};

export default IndustryInsights;
