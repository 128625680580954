import React from 'react';
import { Link } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';

import { Page } from '@nearst/ui';
import LiaPerformanceCard from '../components/LiaPerformanceCard';
import PopularProductsCard from '../components/PopularProductsCard';
import DatePicker from '@components/DatePicker';
import ComparisonCard from '../components/ComparisonCard';
import LocalSearchTermsCard from '../LocalAds/InsightCards/LocalSearchTermsCard';
import { useShop } from '@services/ShopProvider';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { getLocalAdsData } from './queries';

import styles from './LocalAdsChain.module.scss';

const LocalAdsChain = () => {
	const { data: shops } = useShop();
	const { from, to, selectedRange } = useInsightsDate();

	return (
		<>
			<Page.Header>
				<Link to="/insights">
					<Page.BackButton>Insights</Page.BackButton>
				</Link>
				<h1>Local Ads</h1>
				<p>Track and compare the performance of your Local Ads campaigns across all locations.</p>
				<DatePicker showComparison showInterval />
			</Page.Header>
			<Page.Section>
				<div className={styles.fullWidthCard}>
					<LiaPerformanceCard shops={shops} />
					<ComparisonCard
						subtitle={selectedRange}
						query={(filters) => getLocalAdsData({ from, to, ...filters })}
						dimensions={[
							{
								id: 'impressions',
								label: 'Impressions',
								description: (
									<>You are currently comparing the number of times Local Ads have been shown for local searches.</>
								)
							},
							{
								id: 'clicks',
								label: 'Clicks',
								description: <>You are currently comparing the number of times shoppers clicked on your Local Ads.</>
							},
							{
								id: 'ctr',
								label: 'CTR',
								valueSuffix: '%',
								summaryType: 'avg',
								digits: 2,
								description: (
									<>
										You are currently comparing the click-through rate across locations.
										<br />
										This indicates how often your shoppers clicked on a product after seeing it in their search results.
									</>
								)
							},
							{
								id: 'cpc',
								label: 'CPC',
								summaryType: 'avg',
								valuePrefix: getSymbolFromCurrency(shops[0].billingCurrency),
								digits: 2,
								description: (
									<>You are currently comparing how much of your daily budget you spend per click on your Local Ads.</>
								)
							}
						]}
					/>
					<PopularProductsCard />
					<LocalSearchTermsCard shops={shops} />
				</div>
			</Page.Section>
		</>
	);
};

export default LocalAdsChain;
