import { add, sub, startOfDay, format } from 'date-fns';

const productCategories = [
	'Animals & Pet Supplies',
	'Arts & Entertainment',
	'Baby & Toddler',
	'Business & Industrial',
	'Cameras & Optics',
	'Clothing & Accessories',
	'Electronics',
	'Food, Beverages & Tobacco',
	'Furniture',
	'Hardware',
	'Health & Beauty',
	'Home & Garden',
	'Luggage & Bags',
	'Media',
	'Office Supplies',
	'Software',
	'Sporting Goods',
	'Toys & Games',
	'Vehicles & Parts'
];

const createPostgresBarcodeSet = (productList) => {
	try {
		return new Set(productList.map((p) => p.barcode));
	} catch (e) {
		return new Set();
	}
};

const createPostgresBarcodeToplist = (productList) => {
	// key: barcode, value: position (0-based indexing)
	const topList = {};
	for (let i = 0; i < productList.length; i++) {
		topList[productList[i].barcode] = i;
	}
	return topList;
};

const createPostgresQuery = (weekNumber, category) => {
	const startDate = sub(startOfDay(new Date()), { weeks: weekNumber });
	const endDate = add(startDate, { days: 6 });
	const formattedStartDate = format(startDate, 'yyyy-MM-dd');
	const formattedEndDate = format(endDate, 'yyyy-MM-dd');
	return { category, from: formattedStartDate, to: formattedEndDate };
};

const postgresQueries = (selectedCategory) => {
	return [
		createPostgresQuery(4, selectedCategory),
		createPostgresQuery(3, selectedCategory),
		createPostgresQuery(2, selectedCategory),
		createPostgresQuery(1, selectedCategory)
	];
};

const handlePostgresData = ([data1Week, data2Week, data3Week, data4Week]) => {
	const week1Barcodes = createPostgresBarcodeSet(data1Week);
	const week2Barcodes = createPostgresBarcodeSet(data2Week);
	const week3Barcodes = createPostgresBarcodeSet(data3Week);
	const week4Barcodes = createPostgresBarcodeSet(data4Week);

	const week2TopList = createPostgresBarcodeToplist(data2Week);

	return (data1Week || []).map((item, index) => {
		const url = `https://www.google.com/search?q=${encodeURIComponent(item['product_name'])}`;
		const barcode = item['barcode'];

		const calculatePosition = () => {
			let position = 'same';
			let diff = '0';
			let isNew = false;
			if (week2TopList[barcode] === undefined) {
				isNew = true;
				position = 'up';
				diff = 20 - index;
			}
			if (week2TopList[barcode] > index) {
				position = 'up';
				diff = week2TopList[barcode] - index;
			}
			if (week2TopList[barcode] < index) {
				position = 'down';
				diff = week2TopList[barcode] - index;
			}
			return { position, diff, isNew };
		};

		const isTopProduct = () => {
			let topForWeeks = 0;
			for (const topThisWeek of [
				week1Barcodes.has(barcode),
				week2Barcodes.has(barcode),
				week3Barcodes.has(barcode),
				week4Barcodes.has(barcode)
			]) {
				if (topThisWeek) topForWeeks++;
				else break;
			}
			if (topForWeeks <= 2) return false;
			return topForWeeks;
		};

		const isQuickRiser = () => {
			if (index < 5 && !week2Barcodes.has(barcode)) return true;
			return false;
		};

		return {
			productname: item['product_name'].split('[')[0].trim(), // remove "[Book] from end of product name"
			clicks: item['clicksSum'],
			impressions: item['impressionsSum'],
			brand: item['brand'],
			barcode,
			searchUrl: url,
			previousPosition: week2TopList[barcode] === undefined ? '-' : week2TopList[barcode] + 1,

			topProduct: isTopProduct(),
			quickRiser: isQuickRiser(),
			...calculatePosition()
		};
	});
};

export { productCategories, handlePostgresData, postgresQueries };
