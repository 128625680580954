import { eachDayOfInterval, isSameDay, isBefore, format } from 'date-fns';
import Decimal from 'decimal.js';

const formatDate = (date) => format(date, 'yyyy-MM-dd');

export const calculateTotalAdsBudgetForRange = (startDate, endDate, prepaidPlans, addOnPlans) => {
	const days = eachDayOfInterval({
		start: new Date(startDate),
		end: new Date(endDate)
	});

	const budgetByDay = [];

	days.forEach((day, i) => {
		const formattedDate = formatDate(day);
		const prepaidPlanByDay =
			prepaidPlans.find((plan) => isSameDay(new Date(plan.date), new Date(formattedDate))) ||
			(i > 0
				? { date: formattedDate, budget: budgetByDay[i - 1].prePaidBudget }
				: prepaidPlans
						.slice()
						.reverse()
						.find((plan) => isBefore(new Date(plan.date), new Date(formattedDate))) || {
						date: formattedDate,
						budget: 0
					});

		const addOnPlanByDay =
			addOnPlans.find((plan) => isSameDay(new Date(plan.date), new Date(formattedDate))) ||
			(i > 0
				? { date: formattedDate, budget: budgetByDay[i - 1].addOnBudget }
				: addOnPlans
						.slice()
						.reverse()
						.find((plan) => isBefore(new Date(plan.date), new Date(formattedDate))) || {
						date: formattedDate,
						budget: 0
					});

		budgetByDay.push({
			date: formattedDate,
			prePaidBudget: prepaidPlanByDay.budget,
			addOnBudget: addOnPlanByDay.budget
		});
	});
	return budgetByDay;
};

export const calculateTotalAdsBudget = (startDate, endDate, prepaidPlans, addOnPlans) => {
	const budgetByDay = calculateTotalAdsBudgetForRange(startDate, endDate, prepaidPlans, addOnPlans);

	return (day) => {
		const dailyBudget = budgetByDay.find((budgets) => budgets.date === formatDate(day));
		if (dailyBudget) {
			return new Decimal(dailyBudget.prePaidBudget).plus(dailyBudget.addOnBudget).toNumber();
		} else {
			return 0;
		}
	};
};
