import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ClerkProvider } from '@clerk/clerk-react';
import { Root, Page } from '@nearst/ui';
import LoadingContainer from '@components/LoadingContainer';

import AnalyticsWrapper from './components/AnalyticsWrapper';
import ErrorBoundary from './components/ErrorBoundary';
import IntercomProvider from './services/Intercom/Provider';
import MyShopNavigation from './components/MyShopNavigation';
import PrivateRoute from './components/PrivateRouteComponent';
import { ShopProvider } from './services/ShopProvider';
import { SidebarLayout } from './components/Sidebar';

import NotFound from './pages/errors/NotFound';
import { UpgradeModalProvider } from './services/UpgradeProvider';
import { clerkInstance } from './services/auth';
import ScrollToTop from './utils/ScrollToTop';

import 'tippy.js/dist/tippy.css';
import ClerkTheme from './ClerkTheme.module.scss';

import {
	SettingsRouter,
	ChannelsRouter,
	HomeRouter,
	ImpersonateRouter,
	InsightsRouter,
	InventoryRouter,
	LocationsRouter,
	LoginPage,
	OnboardingRouter,
	OrdersRouter,
	RegistrationRouter,
	ReportsRouter,
	SubscriptionRouter,
	UpgradeRouter,
	LogoutPage
} from './pages';

const SignedInRoutes = () => (
	<ErrorBoundary>
		<Switch>
			<Route path="/onboarding" component={OnboardingRouter} />
			<Route path="/home" component={HomeRouter} />
			<Route path="/insights" component={InsightsRouter} />
			<Route path="/inventory" component={InventoryRouter} />
			<Route path="/locations" component={LocationsRouter} />
			<Route path="/channels" component={ChannelsRouter} />
			<Route path="/subscription" component={SubscriptionRouter} />
			<Route path="/settings" component={SettingsRouter} />
			<Route path="/orders" component={OrdersRouter} />
			<Route path="/reports" component={ReportsRouter} />
			<Route path="/logout" component={LogoutPage} />
			<Route path="/upgrade/:productId/:period" component={UpgradeRouter} />
			<Route exact path="/" component={HomeRouter} />
			<Route component={NotFound} />
		</Switch>
	</ErrorBoundary>
);

export default function App() {
	return (
		<IntercomProvider>
			<Root className={ClerkTheme.theme}>
				<ErrorBoundary>
					<ClerkProvider Clerk={clerkInstance}>
						<Switch>
							<Route exact path="/login" component={LoginPage} />
							<Route exact path="/register" component={RegistrationRouter} />
							<Route exact path="/impersonate" component={ImpersonateRouter} />
							<PrivateRoute>
								<ShopProvider>
									<AnalyticsWrapper>
										<UpgradeModalProvider>
											<ScrollToTop />
											<SidebarLayout>
												<MyShopNavigation />
												<Page.Main fallback={<LoadingContainer />}>
													<SignedInRoutes />
												</Page.Main>
											</SidebarLayout>
										</UpgradeModalProvider>
									</AnalyticsWrapper>
								</ShopProvider>
							</PrivateRoute>
						</Switch>
					</ClerkProvider>
				</ErrorBoundary>
			</Root>
		</IntercomProvider>
	);
}
