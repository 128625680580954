import React from 'react';
import { useShop } from '@services/ShopProvider';

const ONBOARDING_STATUSES = [
	'shop.pos.pending',
	'shop.pos.first-upload',
	'shop.signup.handover',
	'shop.google.gmb-requested',
	'shop.google.gmb-approved',
	'shop.google.gmb-nearst-managed',
	'shop.google.setup-started',
	'shop.google.setup-completed',
	'shop.google.pos-link-ok'
];

const EmptyFallback = () => <></>;

/**
 * Callback for checking the shop
 *
 * @callback checkAllowed
 * @param {Object} shop
 * @param {Object[]} [shops]
 */

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {checkAllowed} props.checkAllowed
 * @param {() => JSX.Element} [props.Fallback=EmptyFallback]
 */
const AccessControl = ({ checkAllowed, children, Fallback = EmptyFallback }) => {
	const { shop, data: shops } = useShop();
	let permitted = checkAllowed(shop, shops);

	if (permitted) {
		return <>{children}</>;
	}
	return Fallback;
};

const checkOnboardingPermissions = (shop, allowedState) => {
	const passedOnboarding = !ONBOARDING_STATUSES.includes(shop.onboardingStatus?.type);
	const aheadOfStatus =
		ONBOARDING_STATUSES.findIndex((e) => e === shop.onboardingStatus?.type) >= ONBOARDING_STATUSES.findIndex((e) => e === allowedState);

	return passedOnboarding || aheadOfStatus;
};

/**
 * @param {Object} props
 * @param {string} props.allowedState
 * @param {React.ReactNode} props.children
 * @param {React.ReactNode} [props.Fallback]
 */
const OnboardingAccessControl = ({ allowedState, ...props }) => {
	const checkAllowed = (shop, shops) => {
		if (shop) return checkOnboardingPermissions(shop, allowedState);
		return shops.some((shop) => checkOnboardingPermissions(shop, allowedState));
	};
	return <AccessControl checkAllowed={checkAllowed} {...props} />;
};

export const useIsOnboarding = () => {
	const { shop, data: shops } = useShop();
	if (shop.id) {
		return ONBOARDING_STATUSES.includes(shop.onboardingStatus?.type);
	}
	return shops.every((shop) => ONBOARDING_STATUSES.includes(shop.onboardingStatus?.type));
};

export default OnboardingAccessControl;
