import React from 'react';
import { format, isSameDay } from 'date-fns';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { Button, Pill } from '@nearst/ui';
import { listAllPlans } from '@services/localAds';
import { useInsightsDate } from '@services/InsightsDateProvider';
import * as Stats from '../../../../../components/StatsCard';
import InsightsWrapper from '../../../../../components/Graphs/DataWrapper';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import LocalAdsPerformanceChart from './PerformanceChart';
import LocalAdsFigures from './Figures';
import LIAPill from '../../LocalAds/Pill';

import styles from '../Performance.module.scss';

const LocalAdsPerformance = ({ shops, showGraph = true }) => {
	const onLia = shops.filter((shop) => shop.totalAdsBudget);
	const shop = onLia[0] || shops[0];
	const shopIds = shops.map((shop) => shop.id);

	const { startDate, endDate, selectedRange, lookbackPeriod, interval } = useInsightsDate();

	const { data: allPlans } = useSWR(`${shop.id}-all-plans`, () => listAllPlans({ id: shop.id }), {
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
		shouldRetryOnError: false
	});

	const plans = allPlans ? allPlans.filter((plan) => !isSameDay(new Date(plan.date), new Date(endDate))) : [];

	const liaQuery = {
		id: shopIds,
		table: 'local_ads',
		columns: ['date', 'impressions', 'cpc', 'clicks', 'shop_id'],
		from: format(startDate, 'yyyy-MM-dd'),
		to: format(endDate, 'yyyy-MM-dd'),
		interval,
		sum: ['impressions', 'clicks'],
		avg: ['cpc']
	};

	const prevLiaQuery = {
		id: shopIds,
		table: 'local_ads',
		columns: ['shop_id', 'clicks', 'impressions'],
		sum: ['clicks', 'impressions'],
		from: lookbackPeriod.from,
		to: lookbackPeriod.to,
		interval
	};

	return (
		<>
			<Stats.Card className={`${styles.card} ${styles.performance}`}>
				<Stats.Column>
					<Stats.Header>
						<Stats.Title>
							Local Ads
							<Stats.Tooltip>Location-based product ads on Google.</Stats.Tooltip>
						</Stats.Title>
						{onLia.length > 1 ? <Pill colour="blue">{`${onLia.length} Shops on LIA`}</Pill> : <LIAPill shop={shop} />}
					</Stats.Header>
					<Stats.Subtitle>{selectedRange}</Stats.Subtitle>

					<ErrorBoundary>
						<InsightsWrapper
							query={[liaQuery, prevLiaQuery]}
							Placeholder={
								onLia.length > 0
									? // chain or single shop (live lia & no data) - fallback to <NoData />
										undefined
									: () => {
											// single shop (no lia or data)
											return (
												<Stats.Blur>
													<Stats.Blur.Background>
														<Stats.DefaultFigures />
														{showGraph ? <Stats.DefaultPerformanceChart /> : null}
													</Stats.Blur.Background>
													<Stats.Blur.Foreground>
														<Button to={`/subscription/${shop.id}`} as={Link} disabled={!shop.swisUrl} primary>
															Activate Local Ads to boost in-store sales
														</Button>
													</Stats.Blur.Foreground>
												</Stats.Blur>
											);
										}
							}
						>
							{([liaCurrentPeriod, liaPreviousPeriod]) => {
								return (
									<>
										<LocalAdsFigures liaCurrentPeriod={liaCurrentPeriod} liaPreviousPeriod={liaPreviousPeriod} />
										{showGraph ? (
											<LocalAdsPerformanceChart
												liaCurrentPeriod={liaCurrentPeriod}
												plans={plans}
												startDate={startDate}
												endDate={endDate}
											/>
										) : null}
									</>
								);
							}}
						</InsightsWrapper>
					</ErrorBoundary>
					<div className={styles.viewMoreWrapper}>
						{shops.length > 1 ? (
							<Stats.ViewMore to={`/insights/local-ads`} />
						) : (
							<Stats.ViewMore to={`/insights/${shop.id}/local-ads`} />
						)}
					</div>
				</Stats.Column>
			</Stats.Card>
		</>
	);
};

export default LocalAdsPerformance;
