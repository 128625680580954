import React from 'react';
import { Switcher } from '@nearst/ui';

import * as Stats from '@components/StatsCard';

import { useInsightsDate } from '@services/InsightsDateProvider';
import { format, addDays } from 'date-fns';
import InsightsWrapper from '@components/Graphs/DataWrapper';
import { getDailySwisInsights } from '@services/insights';

import styles from './Overview.module.scss';

const Overview = ({ shops }) => {
	const { startDate, endDate, fromStartOfWeekStr, toEndOfWeekStr } = useInsightsDate();
	const shopIds = shops.map((shop) => shop.id);

	const queries = [
		{
			id: shopIds,
			table: 'local_listings',
			columns: ['impressions', 'clicks', 'shop_id', 'date'],
			from: format(startDate, 'yyyy-MM-dd'),
			to: format(endDate, 'yyyy-MM-dd')
		},
		{
			id: shopIds,
			table: 'local_listings_weekly',
			columns: ['start_date', 'end_date', 'impressions', 'shop_id'],
			filter: [
				['start_date', '>=', fromStartOfWeekStr],
				['end_date', '<=', toEndOfWeekStr]
			],
			sort: [{ column: 'start_date', order: 'desc' }]
		},
		{
			id: shopIds,
			table: 'local_ads',
			columns: ['impressions', 'clicks', 'shop_id'],
			from: format(startDate, 'yyyy-MM-dd'),
			to: format(endDate, 'yyyy-MM-dd'),
			sum: ['impressions', 'clicks']
		},
		{
			id: shopIds,
			table: 'gmb',
			columns: ['website_clicks', 'phone_clicks', 'directions_clicks', 'shop_id'],
			from: format(startDate, 'yyyy-MM-dd'),
			to: format(endDate, 'yyyy-MM-dd'),
			sum: ['website_clicks', 'phone_clicks', 'directions_clicks']
		}
	];

	return (
		<InsightsWrapper query={queries} Placeholder={() => null}>
			{([swisDaily, swisWeekly, liaData, gmbData]) => {
				const swisDailyCorrected = getDailySwisInsights(startDate, endDate, swisDaily, swisWeekly, shopIds);
				const swisClicks = swisDaily.reduce((acc, cur) => (acc += cur.clicks), 0);
				const swisImpressions = swisDailyCorrected.reduce((acc, cur) => (acc += cur.impressions), 0);
				const liaClicks = liaData.reduce((acc, cur) => (acc += cur.clicks), 0);
				const liaImpressions = liaData.reduce((acc, cur) => (acc += cur.impressions), 0);
				const profileInteractions = gmbData.reduce(
					(acc, cur) => (acc += cur.directions_clicks + cur.website_clicks + cur.phone_clicks),
					0
				);

				return (
					<Switcher space="1.5rem" className={styles.stats}>
						<Stats.Figure>
							{(swisImpressions + (liaImpressions || 0)).toLocaleString()}
							<Stats.FigureDescription>Impressions</Stats.FigureDescription>
						</Stats.Figure>
						<Stats.Figure>
							{(swisClicks + (liaClicks || 0)).toLocaleString()}
							<Stats.FigureDescription>Clicks</Stats.FigureDescription>
						</Stats.Figure>
						<Stats.Figure>
							{gmbData.length ? profileInteractions.toLocaleString() : '-'}
							<Stats.FigureDescription>
								Profile Interactions
								<Stats.Tooltip>
									The number of times people have interacted with your profile after viewing it on Google Search or Maps.
									Data available until {format(addDays(new Date(), -4), 'MMM d')}.
								</Stats.Tooltip>
							</Stats.FigureDescription>
						</Stats.Figure>
					</Switcher>
				);
			}}
		</InsightsWrapper>
	);
};

export default Overview;
