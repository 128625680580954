import React from 'react';

import { Page } from '@nearst/ui';
import DatePicker from '@components/DatePicker';
import UpgradeRequiredProductsBanner from '@components/UpgradeRequiredProductsBanner';

import InsightTeaserOverlay from '../components/InsightTeaserOverlay';

import ChainPerformance from './Performance/ChainPerformance';
import LocalListingsPerformance from './Performance/LocalListings';
import LocalAdsPerformance from './Performance/LocalAds';

import ChannelsOverviewCard from './ChannelsOverviewCard';
import { ClicksToSalesStatsCard as ClicksToSales } from './ClicksToSales';
import IndustryInsights from './IndustryInsights';
import InventoryConnection from './InventoryConnection';

import LocalCheckout from './LocalCheckout';

import styles from './InsightsDashboard.module.scss';

const InsightsDashboard = ({ shops, isChain = false }) => {
	const excludedRecords = shops[0].stockInventorySource?.latestIngest ? shops[0].stockInventorySource.latestIngest.excludedRecords : 0;
	const name = isChain ? shops[0].organisation : shops[0].name;

	return (
		<div className={styles.dashboard}>
			<Page.Header>
				<h1>{name}</h1>
				<DatePicker />
			</Page.Header>
			<Page.Section>
				{shops[0].plan === 'freemium' && excludedRecords > 0 ? (
					<UpgradeRequiredProductsBanner unprocessedStock={excludedRecords} isChain={isChain} />
				) : null}
				<div className={styles.container}>
					<div className={styles.container}>
						<InventoryConnection shops={shops} />
						<ChannelsOverviewCard />
					</div>
					<>
						{isChain && (
							<div className={styles.container}>
								<ChainPerformance chain={shops} />
							</div>
						)}
						<div className={styles.container}>
							<LocalAdsPerformance shops={shops} showGraph={!isChain} />
							<LocalListingsPerformance shops={shops} showGraph={!isChain} />
						</div>
					</>
					<LocalCheckout shops={shops}></LocalCheckout>

					{/* no chain state for this yet */}
					{!isChain ? (
						shops[0].plan === 'freemium' ? (
							<InsightTeaserOverlay showButton>
								<div className={`${styles.container} ${styles.teaser}`}>
									<IndustryInsights shops={shops} teaser />
									<ClicksToSales shop={shops[0]} teaser />
								</div>
							</InsightTeaserOverlay>
						) : (
							<div className={styles.container}>
								<IndustryInsights shops={shops} />
								<ClicksToSales shop={shops[0]} />
							</div>
						)
					) : null}
				</div>
			</Page.Section>
		</div>
	);
};

export default InsightsDashboard;
