import React from 'react';
import { format } from 'date-fns';
import * as Stats from '../../../../components/StatsCard';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { Switcher } from '@nearst/ui';
import InsightsWrapper, { getCSVData } from '../../../../components/Graphs/DataWrapper';
import { formatPrice } from '@utils/currency';
import calculateCpc from '../../../../utils/calcCpc';

import styles from './LiaPerformanceCard.module.scss';
import Toggle from '../../../../components/Graphs/Toggle';
import TimeSeriesGraph from '@components/Graphs/TimeSeriesGraph';

const InsightsToggleGraph = ({ data, previousData }) => {
	// sometimes, our previous period data might be shorter than our current period data
	const offset = previousData.length - data.length;

	return (
		<Toggle initialValue="Impressions" values={['Impressions', 'Clicks']}>
			{(measure) => (
				<div className={styles.maxHeight}>
					<TimeSeriesGraph
						series={data.map((row, index) => ({
							date: row.date,
							value: row[measure.toLowerCase()],
							previousDate: previousData[index + offset]?.date,
							previousValue: previousData[index + offset]?.[measure.toLowerCase()]
						}))}
						yAxisLabel={measure}
						dateAxis
					/>
				</div>
			)}
		</Toggle>
	);
};

const createQuery = (id, dateHelpers) => {
	const { startDate, endDate, comparison, interval } = dateHelpers;
	return [
		{
			table: 'local_ads',
			from: format(startDate, 'yyyy-MM-dd'),
			to: format(endDate, 'yyyy-MM-dd'),
			interval,
			id,
			columns: ['clicks', 'impressions', 'cpc', 'date'],
			sort: [{ column: 'date', order: 'asc' }],
			sum: ['clicks', 'impressions'],
			avg: ['cpc']
		},
		{
			table: 'local_ads',
			from: format(comparison.startDate, 'yyyy-MM-dd'),
			to: format(comparison.endDate, 'yyyy-MM-dd'),
			interval,
			id,
			columns: ['clicks', 'impressions', 'cpc', 'date'],
			sort: [{ column: 'date', order: 'asc' }],
			sum: ['clicks', 'impressions'],
			avg: ['cpc']
		}
	];
};

const LiaPerformanceCard = ({ shops, className }) => {
	const ids = shops.map((shop) => shop.id);

	const { startDate, endDate, comparison, selectedRange, interval } = useInsightsDate();
	const [query, previousPeriodQuery] = createQuery(ids, { startDate, endDate, comparison, interval: interval });

	return (
		<Stats.Card className={className}>
			<Stats.Title>Local Ads performance</Stats.Title>
			<Stats.Subtitle>{selectedRange}</Stats.Subtitle>

			<InsightsWrapper query={[query, previousPeriodQuery]} hideError>
				{([data, previousData]) => {
					const csv = getCSVData(data);

					const clicks = data.reduce((acc, cur) => acc + cur.clicks, 0);
					const impressions = data.reduce((acc, cur) => acc + cur.impressions, 0);

					const previousClicks = previousData.reduce((acc, cur) => acc + cur.clicks, 0);
					const previousImpressions = previousData.reduce((acc, cur) => acc + cur.impressions, 0);

					const cpc = calculateCpc(data);
					const ctr = !clicks || !impressions ? 0 : ((clicks / impressions) * 100).toFixed(2);
					const previousCtr =
						!previousClicks || !previousImpressions ? 0 : ((previousClicks / previousImpressions) * 100).toFixed(2);
					const previousCpc = calculateCpc(previousData);

					return (
						<>
							<Stats.Download filename="google-LIA" csv={csv} />
							<Switcher space="2rem" className={styles.stats}>
								<Stats.Block
									description="Impressions"
									currentValue={Number(impressions)}
									previousValue={Number(previousImpressions)}
								/>
								<Stats.Block description="Clicks" currentValue={Number(clicks)} previousValue={Number(previousClicks)} />
								<Stats.Figure>
									{ctr}%
									<Stats.FigureDescription>
										CTR
										<Stats.Tooltip>
											Your click-through rate is a measure of how relevant your products are to the search terms
										</Stats.Tooltip>
										<Stats.PercentageChange previousValue={previousCtr} currentValue={ctr} />
									</Stats.FigureDescription>
								</Stats.Figure>
								<Stats.Figure>
									{formatPrice(cpc, shops[0].billingCurrency)}
									<Stats.FigureDescription>
										CPC
										<Stats.Tooltip>The average you are paying per local ads click from your daily budget</Stats.Tooltip>
										<Stats.PercentageChange previousValue={previousCpc} currentValue={cpc} inverted />
									</Stats.FigureDescription>
								</Stats.Figure>
							</Switcher>
							<InsightsToggleGraph data={data} previousData={previousData} />
						</>
					);
				}}
			</InsightsWrapper>
		</Stats.Card>
	);
};

export default LiaPerformanceCard;
